<div class="padding50 full-vh-height" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
  <div class="row">
    <div class="col-12 flex justify-content-center">
      <span class="font120" [ngClass]="darkMode ? 'textWhite' : 'textBlue'">500</span>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-centre">
      <span class="font16" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">        
        {{app.translations.ERR_Generic}}
        <br>
        {{app.translations.ERR_DirectionP1}}
        <span class="bold cursorPointer" (click)="copy('support@qwix.net')"
          pTooltip="{{app.translations.GBL_hint_Copy}}" tooltipPosition="top">
          supportqwix.net
        </span>
        {{app.translations.ERR_DirectionP2}}
        <span #refError class="bold cursorPointer" (click)="copy(refError.innerText)"
          pTooltip="{{app.translations.GBL_hint_Copy}}" tooltipPosition="top">
          {{errorDetails.refNumber}}
        </span>
      </span>
      <br><br>
      <button class="btn font16 cursorPointer" [ngClass]="darkMode ? 'textWhite' : 'textBlue'" (click)="logout()">
        {{app.translations.LGN_btn_LoginText}}
      </button>
      <br><br>      
    </div>  
    <div class="col-6" style="text-align: end;">
      <span class="bold font16" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
        {{app.translations.ERR_ErrorData}}
      </span> 
      <br>
      <span class="font16" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
        {{errorDetails.errorMessage}}        
      </span>        
    </div>
    <div class="col-6">
      <span class="bold font16" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
        {{app.translations.ERR_ErrorMessage}}  
      </span>
      <br>
      <div class="font16" [ngClass]="darkMode ? 'textWhite' : 'textBlack'" [innerHTML]="errorDetails.errorData"></div>   
    </div>        
  </div>
</div>