import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import { header } from 'app/_misc/headers';
import { IGridData } from 'app/_models/IGridData';

@Injectable({ providedIn: 'root' })
export class GridService {
  constructor(private httpClient: HttpClient) { }  

  getData(formData: FormData) {
    return this.httpClient.post('/grid/getdata', formData, { 
      headers: new header().FormHeader, observe: 'response' 
    }).pipe(map(response => { 
      return response.body as IGridData; 
    }), catchError(this.handleError));
  }

  saveGridLayout(formData: FormData) {
    return this.httpClient.post('grid/savegridlayout', formData, { 
      headers: new header().FormHeader 
    }).pipe(map(response => { 
      return response; 
    }), catchError(this.handleError));
  }   

  getPageNumber(formData: FormData) {
    return this.httpClient.post('/grid/getpagenumber', formData, { 
      headers: new header().FormHeader, observe: 'response' 
    }).pipe(map(response => { 
      return response.body; 
    }), catchError(this.handleError));
  }  

  getKeyField(formData: FormData) {
    return this.httpClient.post('/grid/getkeyfield', formData, { 
      headers: new header().FormHeader, observe: 'response' 
    }).pipe(map(response => { 
      return response.body; 
    }), catchError(this.handleError));
  }  

  handleError(error: any) {    
    return throwError(error);
  }
}