import { Component, HostListener, Input } from '@angular/core';
import { CommunicationService } from 'app/_services/communication.service';

@Component({
  selector: 'image-viewer-component',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.css']
})
export class ImageViewerComponent {
  @Input() image: any;
  @Input() darkMode: boolean;

  constructor(private communicationService: CommunicationService) { }

  close(): void { this.communicationService.closeDialog(); }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Esc') this.close();
  }
}