import { Injectable } from "@angular/core";
import { IActionGroup } from "app/_models/IActionGroup";
import { AppComponent } from "app/app.component";
import { ErrorController } from "./errorController";
import { RADService } from "app/_services/rad.service";
import { IParameter } from "app/_models/IParameter";
import { ILookupValues } from "app/_models/ILookupValues";
import { ICompiledLine } from "app/_models/ICompiledLine";
import { IReportGroup } from "app/_models/IReportGroup";
import { IDashboardGroup } from "app/_models/IDashboardGroup";
import { ISourceViewHeader } from "app/_models/ISourceViewHeader";
import { IGanttTask } from "app/_models/IGanttTask";

@Injectable({ providedIn: 'root' })
export class RADController {
  constructor(
    private app: AppComponent,
    private errorController: ErrorController,   
    private radService: RADService
  ) { }

  getActions(userId: number, nodeId: number): Promise<IActionGroup[]> {
    return new Promise<IActionGroup[]>((resolve) => {
      let actionGroups = [];
      let formData: FormData = this.app.buildForm(['userId', 'nodeId'], [userId.toString(), nodeId.toString()])
      this.radService.getActions(formData).subscribe({
        next: (data: IActionGroup[]) => {
          if(data){
            actionGroups = data;
          }
          resolve(actionGroups);
        }, error: (errorLog) => {
          this.errorController.logError(errorLog, 'radController.getActions() > radService.getActions()');
        }
      });
    });
  }

  getReports(nodeId: number, userId: number): Promise<IReportGroup[]>  {
    return new Promise<IReportGroup[]>((resolve) => {
      let reportGroups = [];
      let formData = this.app.buildForm(['nodeId', 'userId'], [nodeId.toString(), userId.toString()]);
      this.radService.getReports(formData).subscribe({
        next: (data: IReportGroup[]) => {
          if (data) {         
            reportGroups = data;            
          }
          resolve(reportGroups);
        }, error: (errorLog) => {
          this.errorController.logError(errorLog, 'radController.getReports() > radService.getReports()'); 
        }
      });
    });
  }

  getDashboards(nodeId: number, userId: number): Promise<IDashboardGroup[]> {
    return new Promise<IDashboardGroup[]>((resolve) => {
      let dashboardGroups = [];
      let formData = this.app.buildForm(['nodeId', 'userId'], [nodeId.toString(), userId.toString()]);
      this.radService.getDashboards(formData).subscribe({
        next: (data: IDashboardGroup[]) => {
          if (data) {            
            dashboardGroups = data;                        
          }
          resolve(dashboardGroups);
        }, error: (errorLog) => {
          this.errorController.logError(errorLog, 'radController.getDashboards() > radService.getDashboards()');          
        }
      });
    });
  }

  getLookupData(sql: string, filterValue: string, id: string): Promise<ILookupValues[]> {
    return new Promise<ILookupValues[]>((resolve) => { 
      let lookupValues = [];     
      let formData = this.app.buildForm(['sql', 'filterValue', 'id'], [sql, filterValue, id]);
      this.radService.getLookupData(formData).subscribe({
        next: (data: ILookupValues[]) => {     
          if(data) {
            lookupValues = data;
          }     
          resolve(lookupValues);
        }, error: (errorLog) => {
          this.errorController.logError(errorLog, 'radController.getLookupData() > radService.getLookupData()');
        }
      });
    });
  }

  getSourceViewHeaders(nodeId: string, userId: string, sourceView: string): Promise<ISourceViewHeader[]> {
    return new Promise<ISourceViewHeader[]>((resolve) => {
      let sourceViewHeaders = [];   
      let keys = ['nodeId', 'userId', 'sourceView'];
      let values = [nodeId, userId, sourceView];
      let formData = this.app.buildForm(keys, values);
      this.radService.getSourceViewHeaders(formData).subscribe({
        next: (data: ISourceViewHeader[]) => {          
          if(data) {
            sourceViewHeaders = data;
          }     
          resolve(sourceViewHeaders);
        }, error: (errorLog) => {
          this.errorController.logError(errorLog, 'radController.getSourceViewHeaders() > radService.getSourceViewHeaders()');
        }
      });
    });
  }

  sqlExecute(nodeId: string, userId: string, actionId: string, ids: string, executePerLine: boolean): Promise<string> {
    return new Promise<string>((resolve) => {
      let keys = ['nodeId', 'userId', 'actionId', 'ids', 'executePerLine'];
      let values = [nodeId, userId, actionId, ids, executePerLine.toString()];           
      let formData = this.app.buildForm(keys, values);
      this.radService.sqlExecute(formData).subscribe({
        next: (data: { result: string }) => {
          resolve(data.result);
        }, error: (errorLog) => {
          this.errorController.logError(errorLog, 'radController.sqlExecute() > radService.sqlExecute()');
        }
      });
    });
  }

  createUpdateSingleRecord(nodeId: string, userId: string, actionId: string, id: string, parameters?: IParameter[]): Promise<string> {
    return new Promise<string>((resolve) => {
      let keys = ['nodeId', 'userId', 'actionId', 'id', 'parameters'];
      let values = [nodeId, userId, actionId, id, JSON.stringify(parameters)];           
      let formData = this.app.buildForm(keys, values);
      this.radService.createUpdateSingleRecord(formData).subscribe({
        next: (data: { result: string }) => {
          resolve(data.result);
        }, error: (errorLog) => {
          this.errorController.logError(errorLog, 'radController.createUpdateSingleRecord() > radService.createUpdateSingleRecord()');
        }
      });
    });        
  }

  updateMultipleRecords(nodeId: string, userId: string, actionId: string, ids: string, parameters?: IParameter[]): Promise<string> {
    return new Promise<string>((resolve) => {
      let keys = ['nodeId', 'userId', 'actionId', 'ids', 'parameters'];
      let values = [nodeId, userId, actionId, ids, JSON.stringify(parameters)];           
      let formData = this.app.buildForm(keys, values);
      this.radService.updateMultipleRecords(formData).subscribe({
        next: (data: { result: string }) => {
          resolve(data.result);
        }, error: (errorLog) => {
          this.errorController.logError(errorLog, 'radController.updateMultipleRecords() > radService.updateMultipleRecords()');
        }
      });
    });
  }

  updateMultipleRecordsPrepopulated(nodeId: string, userId: string, actionId: string, parameters: IParameter[], compiledLines: ICompiledLine[]): Promise<string> {
    return new Promise<string>((resolve) => {
      let keys = ['nodeId', 'userId', 'actionId', 'parameters', 'compiledLines'];
      let values = [nodeId, userId, actionId, JSON.stringify(parameters), JSON.stringify(compiledLines)];           
      let formData = this.app.buildForm(keys, values);
      this.radService.updateMultipleRecordsPrepopulated(formData).subscribe({
        next: (data: { result: string }) => {
          resolve(data.result);
        }, error: (errorLog) => {
          this.errorController.logError(errorLog, 'radController.updateMultipleRecordsPrepopulated() > radService.updateMultipleRecordsPrepopulated()');
        }
      });
    });
  }

  filterRadButtons<T extends (IActionGroup[] | IReportGroup[] | IDashboardGroup[]), U extends ICompiledLine[] | IGanttTask[]>(group: T, currentViewName: string, compiledItems: U, isActions?: boolean): T {
    group.forEach(group => {
      group.value.forEach(button => {
        if (isActions && button.largeIconSerialized != null) {
          button.imageIcon = `data:image/png;base64, ${button.largeIconSerialized}`;
        }

        if (button.activeViews != null) {
          if (!button.executePerLine) {            
            button.disabled = false;
          } else if (button.activeViews == currentViewName) {
            if (button.executePerLine && compiledItems.length == 0) {
              button.disabled = true;
            } else {
              button.disabled = false;
            }
          } else {
            button.disabled = true;
          }
        } else {        
          button.disabled = false;
        }
      })
    })
    
    return group;
  }
}