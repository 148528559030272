import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';
import { LoginService } from 'app/_services/login.service';
import { LicensingService } from 'app/_services/licensing.service';
import { CommunicationService } from 'app/_services/communication.service';
import { IconDefinition, faEye, faEyeSlash, faCircleInfo, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-login-component',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit { 
  //#region Fields 
  showPassword: boolean = false;
  type: string = 'password';
  changePassword: boolean = false;
  username: string;
  passwordStrength: string;
  cameFromSettings: boolean = false;
  translationsLoaded: boolean = false;
  feedbackMessage: string = undefined;   
  resetPasswordToken: string = undefined;
  faEye: IconDefinition = faEye;
  faEyeSlash: IconDefinition = faEyeSlash;
  faCircleInfo: IconDefinition = faCircleInfo;
  faArrowLeft: IconDefinition = faArrowLeft;
  //#endregion 

  constructor(
    private app: AppComponent,
    private loginService: LoginService,
    private licensingService: LicensingService,
    private communicationService: CommunicationService
  ) { 
    this.resetPasswordToken = undefined;
    this.communicationService.feedback_login_closeFeedbackDialog$.subscribe(() => {
      this.feedbackMessage = undefined;
    });
  }

  ngOnInit(): void {
    this.app.getTranslations();
    this.communicationService.app_base_or_login_translationsLoaded$.subscribe(() => {
      this.translationsLoaded = true;
    });

    if (sessionStorage.getItem('resetPassword') != undefined && sessionStorage.getItem('resetPassword').toString() == 'true') {
      this.changePassword = true;
      this.cameFromSettings = true;      
      this.username = this.app.cookieService.get('loggedIn').split('|')[2];
    }

    const urlTree = this.app.router.parseUrl(this.app.router.url);
    const queryParams = urlTree.queryParams;
    let queryValues = [];
    Object.keys(queryParams).forEach(key => {
      queryValues.push({
        key: key,
        value: queryParams[key]
      });
    });  
    
    if(queryValues.length > 0) {      
      let formData = this.app.buildForm(['token'], [queryValues[0].value]);
      this.loginService.validateToken(formData).subscribe({
        next: (data: { result: boolean }) => {                  
          if(data.result) {
            this.resetPasswordToken = queryValues[0].value;
            this.changePassword = true;
            return;
          } 
          
          this.app.alertError(this.app.translations.ALERT_Error_InvalidToken);
        }, error: (errorLog) => {
          console.log(errorLog);
        }
      })      
    }
  }

  back(): void {
    if (this.cameFromSettings) {
      this.app.router.navigate(['base']);
      return;
    }

    this.changePassword = false;     
    this.resetPasswordToken = undefined;
  }

  validatePassword(newPassword: string): void {
    if (newPassword.length < 8) {
      this.passwordStrength = 'password-weak';
      return;
    }

    let uppercase = /[A-Z]/;
    let lowercase = /[a-z]/;
    let number = /[0-9]/;
    let special = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

    if (uppercase.test(newPassword) && lowercase.test(newPassword) && number.test(newPassword)) {
      this.passwordStrength = special.test(newPassword) ? 'password-strong' : 'password-medium';
    }
  }

  forgotPassword(username: string): void {
    if (!username) {
      this.app.alertError(this.app.translations.ALERT_Error_UsernameRequired);
      return;
    }

    this.username = username;    
    let formData = this.app.buildForm(['username'], [username]);
    this.loginService.forgotPassword(formData).subscribe({
      next: (data: { result: string }) => {        
        this.feedbackMessage = data.result;
      }, error: (errorLog) => {
        console.log(errorLog);
      }
    })
  }  

  resetPassword(currentPasswordOrUsername: string, newPassword: string, confirmPassword: string): void {
    if (currentPasswordOrUsername.length == 0 && !this.resetPasswordToken) {
      this.app.alertError(this.app.translations.ALERT_Error_CurrentPasswordRequired);
      return;
    }

    if (currentPasswordOrUsername.length == 0 && this.resetPasswordToken) {
      this.app.alertError(this.app.translations.ALERT_Error_UsernameRequired);
      return;
    }

    if (newPassword.length == 0) {
      this.app.alertError(this.app.translations.ALERT_Error_NewPasswordRequired);
      return;
    }

    if (confirmPassword.length == 0) {
      this.app.alertError(this.app.translations.ALERT_Error_ConfirmPassword);
      return;
    }

    if (confirmPassword.length > 0 && confirmPassword != newPassword) {
      this.app.alertError(this.app.translations.ALERT_Error_PasswordMisMatch);
      return;
    }

    if (this.passwordStrength == 'password-weak') {
      this.app.alertError(this.app.translations.ALERT_Error_WeakPassword);
      return;
    }

    if(!this.resetPasswordToken) {
      let keys = ['username', 'currentPassword', 'newPassword'];
      let values = [this.username, currentPasswordOrUsername, newPassword];
      let formData = this.app.buildForm(keys, values);
      this.loginService.resetPassword(formData).subscribe({
        next: (data: { result: boolean }) => {
          if (data.result) {
            this.login(this.username, newPassword);
          }
        }, error: (errorLog) => {
          console.log(errorLog);
        }
      })
    } else {
      let keys = ['token', 'username', 'password'];
      let values = [this.resetPasswordToken, currentPasswordOrUsername, newPassword];
      let formData = this.app.buildForm(keys, values);
      this.loginService.resetForgotPassword(formData).subscribe({
        next: (data: { result: string }) => {
          if (data.result) {            
            if(data.result.includes('ERROR')) {
              this.feedbackMessage = data.result;
              return;
            }
            
            this.username = currentPasswordOrUsername;
            this.login(this.username, newPassword);     
          }
        }, error: (errorLog) => {
          console.log(errorLog);
        }
      })
    }    
  }

  login(username: string, password: string): void {
    if (username.length == 0) {
      this.app.alertError(this.app.translations.ALERT_Error_UsernameRequired);
      return;
    }

    if (password.length == 0) {
      this.app.alertError(this.app.translations.ALERT_Error_PasswordRequired);
      return;
    }

    let formData = this.app.buildForm(['username', 'password'], [username, password]);
    this.loginService.loginUser(formData).subscribe({
      next: (data: { id: number, passwordExpired: string }) => {
        if (data) {
          if (data.passwordExpired == 'EXPIRED') {
            this.username = username;
            this.app.alertInfo(this.app.translations.ALERT_Info_PasswordExpired);
            this.changePassword = true;
            return;
          }
          
          this.app.cookieService.set('loggedIn', `true|${data.id}|${username}`, 7, '/', '', false);
          this.app.getTranslations();//reload translations with logged in user id
          if (sessionStorage.getItem('directReportURL') != undefined) {          
            this.app.router.navigate(['viewer']);
          }

          if (data.passwordExpired == 'OK') {            
            this.app.router.navigate(['base']);
          }

          if (data.passwordExpired != 'EXPIRED' && data.passwordExpired != 'OK' && data.passwordExpired != 'Note') {            
            this.app.alertInfo(data.passwordExpired);
            setTimeout(() => this.app.router.navigate(['base']), 2000);
            return;
          }                    
        } else {
          this.app.alertError(this.app.translations.ALERT_Error_WrongCredentials);
        }
      }, error: (errorLog) => {
        console.log(errorLog);
      }
    });
  }
}
