import { Injectable, NgZone } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { LicensingService } from './licensing.service';
import { Subscription, interval } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class InactivityService {
  private activityEvents = ['mousemove', 'click'];
  private timeoutId: any;
  sessionValidationSubject: Subscription;
  isActive: boolean = true;

  constructor(
    private ngZone: NgZone,
    public app: AppComponent,
    private licensingService: LicensingService
  ) {
    localStorage.removeItem('logoutReason');
    localStorage.removeItem('sessionResult');
    this.startMonitoring();
    this.resetTimeout();
  }

  logout(): void {
    this.stopMonitoring();
    this.terminateSession();
    sessionStorage.clear();
    this.app.cookieService.delete('loggedIn');
    window.location.href = '/signin';
  }

  //#region Inactivity Validation
  startMonitoring(): void {
    this.activityEvents.forEach(event => {
      window.addEventListener(event, this.resetTimeout.bind(this));
    });
  }

  stopMonitoring(): void {
    this.activityEvents.forEach(event => {
      window.removeEventListener(event, this.resetTimeout.bind(this));
    });
    this.clearTimeout();
  }

  resetTimeout(): void {
    this.clearTimeout();
    if(!this.isActive) {
      console.log('session validation restarted'); 
      this.isActive = true;
      this.startSessionValidation();
      this.validateSession();
    }

    this.ngZone.runOutsideAngular(() => {
      this.timeoutId = window.setTimeout(() => {
        this.ngZone.run(() => this.stopSessionValidation());
      }, 125000);//2.5min
    });
  }  

  clearTimeout(): void {    
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);      
    }
  }
  //#endregion
  
  //#region Session Validation
  startSessionValidation(): void {
    console.log('session validation started');    
    this.sessionValidationSubject = interval(120000).subscribe(() => {//30sec - ideally 2min 120000
      this.validateSession();
    });
  }
  
  stopSessionValidation(): void {
    this.isActive = false;
    if (this.sessionValidationSubject) {
      this.sessionValidationSubject.unsubscribe();
      console.log('session validation stopped');
    }
  }
  
  validateSession(): void {
    
    console.log('validating session...');
    let formData = this.app.buildForm(['sessionId'], [localStorage.getItem('sessionId')])
    this.licensingService.validateSession(formData).subscribe({
      next: (data: { result: boolean, message: string }) => {
        console.log('session validated: ', data.result);
        if (!data.result) {
          this.stopSessionValidation();
          localStorage.setItem('sessionResult', `Result: ${data.result}. Message: ${data.message}`);
          localStorage.setItem('logoutReason', 'Logged out due to invalid session');
          this.logout();
        }
      }, error: (errorLog) => {
        console.log(errorLog);
      }
    })
  }

  terminateSession(): void {
    console.log('terminating session...');
    let sessionId = Number(localStorage.getItem('sessionId'));
    this.stopSessionValidation();
    if (sessionId > 0) {
      localStorage.removeItem('sessionId');
      let formData = this.app.buildForm(['sessionId'], [sessionId.toString()]);
      this.licensingService.terminateSession(formData).subscribe({
        next: () => {
          console.log('terminated session');
        }, error: (errorLog) => {
          console.log(errorLog);
        }
      })
    }
  }
  //#endregion
}
