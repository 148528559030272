<div class="row view-background animated slideInLeft">
  <div class="col-12 flex full-vh-height align-centre justify-centre background-overLay">
    <div class="animated fadeIn box-shadow b-radius text-centre login-panel">
      <img src="../assets/img/logo.png" class="cursorPointer login-logo">
      <ng-template [ngIf]="!changePassword && translationsLoaded">
        <h4 class="textWhite font25 cursorPointer flex justify-centre login-title poppins">
          {{app.translations.LGN_lbl_Login}}</h4>
        <input #username type="text" placeholder="{{app.translations.LGN_place_Username}}"
          (keyup.enter)="login(username.value, password.value);" class="form-control bg-none textWhite font17"
          [ngClass]="errorUsername"><br>
        <div class="relative">
          <input #password type="{{type}}" class="form-control bg-none textWhite font17"
            (keyup.enter)="login(username.value, password.value);" placeholder="{{app.translations.LGN_place_Password}}"
            [ngClass]="errorPassword">
          <ng-template [ngIf]="!showPassword">
            <i class="fa-solid fa-eye textBlack font14 cursorPointer absolute password-check-icon"
              (click)="showPassword = !showPassword; type = 'text';"></i>
          </ng-template>
          <ng-template [ngIf]="showPassword">
            <i class="fa-solid fa-eye-slash textBlack font14 cursorPointer absolute password-check-icon"
              (click)="showPassword = !showPassword; type = 'password';"></i>
          </ng-template>
        </div><br><br>
        <button class="textWhite font18 bg-green b-radius full-width unset-b login-btn"
          (click)="login(username.value, password.value);">
          {{app.translations.LGN_btn_LoginText}}
        </button>
        <br><br><br>
        <span class="textWhite font17 pull-left cursorPointer" (click)="forgotPassword(username.value);">
          {{app.translations.LGN_lbl_ForgotpwText}}?
        </span>
      </ng-template>
      <ng-template [ngIf]="changePassword && translationsLoaded">
        <h4 class="textWhite font25 cursorPointer flex justify-centre poppins">
          {{app.translations.LGN_lbl_ResetPassword}}</h4>
        <input [type]="resetPasswordToken ? 'text' : 'password'" #currentPassword
          class="form-control bg-none textWhite font17" autocomplete="new-password"
          [placeholder]="resetPasswordToken ? app.translations.LGN_place_Username : app.translations.LGN_place_CurrentPassword" />
        <br /><br />
        <div class="relative">
          <input type="password" #newPassword class="form-control bg-none textWhite font17" [ngClass]="passwordStrength"
            placeholder="{{app.translations.LGN_place_NewPassword}}" autocomplete="new-password"
            (keyup)="validatePassword(newPassword.value, confirmPassword.value)" /><br /><br />
          <i class="fa-solid fa-circle-info textBlack font15 absolute" style="top: 15%; right: 1%;"
            pTooltip="{{app.translations.LGN_hint_NewPassword}}" tooltipPosition="top"></i>
        </div>
        <input type="password" #confirmPassword class="form-control bg-none textWhite font17"
          autocomplete="new-password" placeholder="{{app.translations.LGN_place_ConfirmPassword}}" /><br /><br />
        <div class="flex">
          <button class="textWhite font18 bg-green b-radius unset-b back-btn"
            pTooltip="{{app.translations.LGN_hint_Back}}" tooltipPosition="top" (click)="back()">
            <i class="fa-solid fa-arrow-left textWhite font18"></i>
          </button>
          <button class="textWhite font18 bg-green b-radius unset-b reset-btn"
            (click)="resetPassword(currentPassword.value, newPassword.value, confirmPassword.value)">
            {{app.translations.LGN_btn_ResetText}}
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template [ngIf]="feedbackMessage">
  <feedback-component [feedbackMessage]="feedbackMessage" [darkMode]="false" [fromLogin]="true"></feedback-component>
</ng-template>