<p-dialog [visible]="true" [breakpoints]="{'430px': '100%'}" [draggable]="false" [resizable]="true"
  [style]="{width: '40%'}" [baseZIndex]="10000" [modal]="true" [maximizable]="false"
  [ngClass]="{'change-scrollbar-color-darkMode': darkMode, 'change-scrollbar-color': !darkMode,}">
  <div class="row sticky dialog-title-container" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-lg-12 unset-p">
      <div class="flex relative align-centre dialog-title">
        <span class="textGreen font16 b-radius dialog-title-icon"
          [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
          <i class="fa-solid fa-bell"></i>
        </span>
        <span class="font17" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
          {{app.translations.NOT_lbl_Title}}
        </span>
        <div class="absolute" style="right: 15px;">
          <span class="textGreen font16 b-radius dialog-title-icon cursorPointer" (click)="close()"
            [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <ng-template [ngIf]="notificationGroups.length == 0">
    <div class="row animated fadeIn" [ngStyle]="{'background-color': darkMode ? '#00000099' : '#ededed'}">
      <span class="font15 padding10 text-centre" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
        {{app.translations.NOT_lbl_NoNotifications}}
      </span>
    </div>
  </ng-template>
  <ng-template [ngIf]="notificationGroups.length > 0">
    <div class="row unset-p" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
      <div class="padding10">
        <mat-tab-group class="tabGroup" [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="changeTab($event)"
          [@.disabled]="true">
          <ng-container *ngFor="let group of notificationGroups">
            <mat-tab class="animated fadeIn">
              <ng-template mat-tab-label>
                <span [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
                  <i class="fa-regular" [ngClass]="group.icon"></i>
                  {{group.category}}
                </span>
              </ng-template>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
        <ng-container *ngFor="let notificationGroup of notificationGroups; let i = index">
          <ng-template [ngIf]="i == selectedTabIndex">
            <ng-template [ngIf]="notificationGroup.notifications.length > 0">
              <ng-container *ngFor="let notification of notificationGroup.notifications">
                <div class="row animated fadeIn notifications-container"
                  [ngStyle]="{'animation-delay': notification.animationDelay}">
                  <div class="row">
                    <div class="cursorPointer padding10 b-radius" (click)="readNotification(notification)"
                      [ngStyle]="{
                         'border-left': !notification.read ? '2px solid var(--blue)' : '2px solid #eaf1ff',
                         'background-color': (notification.read ? (darkMode ? '#ededed2b' : '#ededed') : (darkMode ? '#eaf1ff63' : '#eaf1ff'))}">
                      <div class="col-12 unset-p">
                        <span class="font16 bold"
                          [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{notification.subject}}</span>
                          <button class="font16 float-right bg-none unset-b">
                            <i class="fa-solid fa-xmark" [ngClass]="darkMode ? 'textWhite' : 'textGreen'"
                              (click)="deleteNotification(notification)"></i>
                          </button>
                          <span class="font16 float-right bold" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
                            {{notification.date | date:'yyyy-MM-dd HH:mm:ss'}}
                          </span>                                                   
                      </div><br />
                      <div class="col-12 unset-p">
                        <div class="poppins font15" [ngClass]="darkMode ? 'textWhite' : 'textBlack'"
                          [innerHTML]="notification.body"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-template>
            <ng-template [ngIf]="notificationGroup.notifications.length == 0">
              <div class="row animated fadeIn text-centre padding10">
                <span class="font15 padding15 b-radius"
                  [ngStyle]="{'background-color': darkMode ? '#ededed33' : '#ededed'}"
                  [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
                  {{app.translations.NOT_lbl_Empty}}
                </span>
              </div>
            </ng-template>
          </ng-template>
        </ng-container>
      </div>
    </div>
  </ng-template>
</p-dialog>