import { Component, HostListener, Input } from '@angular/core';
import { AppComponent } from 'app/app.component';
import { CommunicationService } from 'app/_services/communication.service';
import { RADController } from 'app/_controllers/radController';
import { ICompiledLine } from 'app/_models/ICompiledLine';
import { IAction } from 'app/_models/IAction';
import { IGanttTask } from 'app/_models/IGanttTask';

@Component({
  selector: 'sql-action-component',
  templateUrl: './sql-action.component.html',
  styleUrls: ['./sql-action.component.css']
})
export class SqlActionComponent {
  //#region Fields
  @Input() action: IAction;
  @Input() compiledItems: ICompiledLine[] | IGanttTask[] = [];
  @Input() nodeId: string;
  @Input() userId: string;
  @Input() darkMode: boolean;    
  //#endregion Fields

  constructor(
    public app: AppComponent,
    private radController: RADController,
    private communicationService: CommunicationService
  ) { }  

  confirmDialog(): void {
    this.app.showLoader();    
    let firstElement = this.compiledItems[0];
    let ids = firstElement && 'lineData' in firstElement ? this.compiledItems.map(line => line.id).join(';') : '';
    this.radController.sqlExecute(this.nodeId, this.userId, this.action.name, ids, this.action.executePerLine).then(result => {
      this.app.hideLoader();
      if (result == '') {
        this.app.alertSuccess(this.app.translations.ALERT_Success_Success);
        this.close();
        this.communicationService.refreshAll();
        return;
      }

      this.communicationService.showFeedbackDialog(result);      
    });
  }

  close(): void {
    this.communicationService.closeDialog();   
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Esc') this.close();
  }
}
