<div class="row main transition5"
  [ngClass]="{'change-scrollbar-color-darkMode': darkMode, 'change-scrollbar-color': !darkMode}">
  <div class="animated fadeIn unset-p" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="relative padding5 full-width transition5 auto-height"
      [ngClass]="{'bg-dark': darkMode, 'bg-white': !darkMode}">
      <div class="row" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
        <div class="padding5 b-radius control-bar" [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
          <div class="col-3 flex unset-p flex align-centre">
            <select #ddl class="font14 unset-b padding5 b-radius unset-outline cursorPointer"
              [ngClass]="darkMode ? 'bg-dark-secondary textWhite' : 'bg-white textBlack'"
              (change)="selectValue($event, '')">
              <option class="cursorPointer textBlack" [value]="0">{{app.translations.GBL_lbl_SelectOption}}</option>
              <ng-container *ngFor="let val of projectData">
                <option class="cursorPointer textBlack" [value]="val.id" [selected]="val.id == keyValue">
                  {{val.value}}
                </option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>
      <div class="animated fadeIn" style="margin-top: 5px;">
        <dx-gantt #ganttChart taskListWidth="500" scaleType="weeks" style="height: 85vh !important;" class="b-radius"
          (onTaskClick)="selectTask($event);">

          <dxo-tasks disabled="true" [dataSource]="ganttTasks" keyExpr="id" parentIdExpr="parent" titleExpr="title"
            progressExpr="progress" startExpr="start" endExpr="end" colorExpr="color"></dxo-tasks>
          <dxo-dependencies [dataSource]="ganttDependencies"></dxo-dependencies>

          <dxi-column dataField="title" caption="Subject" [width]="300"></dxi-column>
          <dxi-column dataField="start" caption="Start Date" dataType="date"></dxi-column>
          <dxi-column dataField="end" caption="End Date" dataType="date"></dxi-column>

          <dxo-toolbar>
            <dxi-item name="zoomIn"></dxi-item>
            <dxi-item name="zoomOut"></dxi-item>
          </dxo-toolbar>

          <dxo-editing [enabled]="true" [allowDependencyAdding]="false" [allowDependencyDeleting]="false"
            [allowResourceAdding]="false" [allowResourceDeleting]="false" [allowTaskAdding]="false"
            [allowTaskDeleting]="false" [allowTaskResourceUpdating]="false" [allowTaskUpdating]="false">
          </dxo-editing>
        </dx-gantt>
      </div>
    </div>
  </div>
</div>