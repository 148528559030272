import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import { header } from 'app/_misc/headers';
import { INavigationItem } from 'app/_models/INavigationItem';

@Injectable({ providedIn: 'root' })
export class MenuService {
  constructor(private httpClient: HttpClient) { }  

  getMenu(formData: FormData) {
    return this.httpClient.post('/menu/getmenu', formData, { 
      headers: new header().FormHeader, observe: 'response' 
    }).pipe(map(response => { 
      return response.body as INavigationItem[]; 
    }), catchError(this.handleError));
  }

  handleError(error: any) {
    return throwError(error);
  }
}