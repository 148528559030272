<body [ngClass]="{'overflow-x-hidden': showLoaderModal}">
  <ng-template [ngIf]="showLoaderModal">
    <div class="absolute animated fadeIn custom-loaderContainer">
      <div class="absolute custom-loader"></div>
    </div>
  </ng-template>
  <router-outlet></router-outlet>
  <div class="font15 textWhite flex fixed align-centre alert alert-{{type}} animated slideInRight" [ngClass]="dismiss">
    <span class="textWhite">
      <i class="fa-solid fa-{{icon}}"></i> {{message}}
    </span>
  </div>
</body>