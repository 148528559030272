import { Component, OnInit, HostListener } from '@angular/core';
import { AppComponent } from '../app.component';
import { Location } from '@angular/common';
import { IError } from 'app/_models/IError';

@Component({
  selector: 'error-component',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  darkMode: boolean = false;  
  errorDetails: IError;

  constructor(
    public app: AppComponent,
    private location: Location
  ) {
    if (this.app.cookieService.check('darkMode')) 
      this.darkMode = this.app.tryParseBoolean(this.app.cookieService.get('darkMode'));

    if(sessionStorage.getItem('errorDetails')) {
      this.app.hideLoader();
      this.errorDetails = JSON.parse(sessionStorage.getItem('errorDetails'));      
      sessionStorage.removeItem('errorDetails');
      console.log(this.errorDetails);            
    }
  }

  ngOnInit() { window.addEventListener('popstate', this.handlePopState); }
  ngOnDestroy(): void { window.removeEventListener('popstate', this.handlePopState); }
  handlePopState(): void { }
  @HostListener('window:popstate', ['$event'])
  onPopState(event): void { this.location.forward(); }

  copy(text: any): void {
    this.app.clipboard.copy(text);
    this.app.alertInfo(this.app.translations.ALERT_Info_Copied);
  }

  logout(): void {
    sessionStorage.clear();
    this.app.cookieService.delete('loggedIn');
    window.location.href = '/signin';
  }
}
