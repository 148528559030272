<div>
  <div class="row" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'"
    style="padding: 5px !important; margin-bottom: 5px !important;">
    <div class="padding5 b-radius control-bar" [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
      <div class="col-6 flex unset-p flex align-centre">
        <select #ddl class="font14 unset-b padding5 b-radius unset-outline cursorPointer"
          [ngClass]="darkMode ? 'bg-dark-secondary textWhite' : 'bg-white textBlack'" (change)="selectValue($event)">
          <option class="cursorPointer textBlack" [value]="0">{{app.translations.GBL_lbl_SelectOption}}</option>
          <ng-container *ngFor="let val of mainData">
            <option class="cursorPointer textBlack" [value]="val.id" [selected]="val.id == keyValue">
              {{val.value}}
            </option>
          </ng-container>
        </select>
        <ng-template [ngIf]="masterConfig && keyValue">
          <button class="btn font17" (click)="openColumnDialog()">
            <i class="fa-solid fa-table-columns padding3 b-radius textGreen control-bar-btn-icon"></i>
            <span class="control-bar-btn-text"
              [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_btn_ColumnsText}}</span>
          </button>
          <button class="btn font17" (click)="toggleFilters()">
            <i class="fa-solid fa-filter textGreen padding3 b-radius control-bar-btn-icon"></i>
            <span class="control-bar-btn-text"
              [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_btn_FilterText}}</span>
          </button>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <p-table [columns]="headers" [reorderableColumns]="false" styleClass="p-datatable-gridlines"
        [resizableColumns]="false">
        <ng-template pTemplate="header" let-columns>
          <tr>
            <ng-container *ngFor="let header of headers">
              <ng-container *ngFor="let filter of filters">
                <ng-template [ngIf]="header.visible && header.caption == filter.caption">
                  <th class="fit-content-max-width" (dblclick)="removeSort(header.field)"
                    [ngClass]="['padding' + tablePadding, 'font' + globalFontSize, darkMode ? 'bg-transparent': 'bg-white']">
                    <span class="word-break" (click)="sort(header)" [ngClass]="{'sorted-column': colSortedBy === header.field, 
                'active-filter': filter.isActive && !darkMode, 
                'active-filter-dark': filter.isActive && darkMode,
                'textGreyb9': filter.isNull, 
                'textBlack': !filter.isNull && !darkMode, 
                'textWhite': !filter.isNull && darkMode,
                'cursorPointer': header.displayFormat != 'IMAGE',
                'cursorNotAllowed': header.displayFormat == 'IMAGE'}">
                      <ng-template [ngIf]="sortString && colSortedBy == header.field">
                        <i class="fa-solid"
                          [ngClass]="[sortOrder ? 'fa-arrow-up-long' : 'fa-arrow-down-long', 'font' + globalFontSize, 'padding' + tablePadding]"></i>
                      </ng-template>
                      {{header.caption}}
                    </span>
                  </th>
                </ng-template>
              </ng-container>
            </ng-container>
          </tr>
          <ng-template [ngIf]="showFilters">
            <tr>
              <ng-container *ngFor="let header of headers">
                <ng-container *ngFor="let filter of filters">
                  <ng-template [ngIf]="header.visible && header.caption == filter.caption">
                    <ng-template [ngIf]="header.displayFormat == 'TIME'">
                      <td (dblclick)="clearFilter(filter)" class="relative cursorPointer"
                        [ngClass]="['padding' + tablePadding, darkMode ? 'bg-dark' : 'bg-white']">
                        <button class="absolute bg-none unset-p unset-b range-filter-btn"
                          [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" (click)="op.toggle($event)">
                          <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                        </button>
                        <p-overlayPanel #op>
                          <ng-template pTemplate="content">
                            <div class="b-radius box-shadow" style="padding: 20px 0px 10px;"
                              [ngClass]="[darkMode ? 'bg-dark' : 'bg-white']">
                              <div class="row">
                                <div class="col-12 unset-p">
                                  <span class="font15 padding5 b-radius"
                                    [ngClass]="[darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-light-blue']">
                                    {{app.translations.GBL_lbl_ChooseRange}}
                                  </span>
                                  <ng-template [ngIf]="filter.isNull">
                                    <span class="font14 textGreyb9 float-right">null</span>
                                  </ng-template>
                                </div>
                              </div><br />
                              <div class="row">
                                <div class="col-6">
                                  <span class="font14" [ngClass]="[darkMode ? 'textWhite' : 'textBlack']">
                                    {{app.translations.GBL_lbl_RangeFrom}}
                                  </span><br />
                                  <input class="form-control textBlack font14 input-filter" step=1 type="time" #txtFrom
                                    [value]="filter.range.length > 0 ? filter.range[0] : ''"
                                    (contextmenu)="nullifyDateFilter(filter, header, $event)" />
                                </div>
                                <div class="col-6">
                                  <span class="font14" [ngClass]="[darkMode ? 'textWhite' : 'textBlack']">
                                    {{app.translations.GBL_lbl_RangeTo}}
                                  </span><br />
                                  <input class="form-control textBlack font14 input-filter" step=1 type="time" #txtTo
                                    [value]="filter.range.length > 0 ? filter.range[1] : ''"
                                    (contextmenu)="nullifyDateFilter(filter, header, $event)" />
                                </div>
                              </div><br />
                              <div class="row">
                                <div class="col-12 flex justify-right">
                                  <button class="btn font17" (click)="createRangeFilter(filter)">
                                    <i class="fa-solid fa-check textGreen padding3 b-radius control-bar-btn-icon"></i>
                                    <span class="control-bar-btn-text"
                                      [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_btn_ApplyText}}</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </p-overlayPanel>
                      </td>
                    </ng-template>
                    <ng-template [ngIf]="header.displayFormat == 'DATE'">
                      <td (dblclick)="clearFilter(filter)" class="relative cursorPointer"
                        [ngClass]="['padding' + tablePadding, darkMode ? 'bg-dark' : 'bg-white']">
                        <button class="absolute bg-none unset-p unset-b range-filter-btn" tooltipPosition="top"
                          [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" (click)="op.toggle($event)"
                          pTooltip="{{getDateFilterTooltip(filter)}}" [tooltipDisabled]="filter.range.length == 0">
                          <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                        </button>
                        <p-overlayPanel #op>
                          <ng-template pTemplate="content">
                            <div class="b-radius box-shadow" style="padding: 20px 0px 10px;"
                              [ngClass]="[darkMode ? 'bg-dark' : 'bg-white']">
                              <div class="row">
                                <div class="col-12">
                                  <span class="font15 padding5 b-radius"
                                    [ngClass]="[darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-light-blue']">
                                    {{app.translations.GBL_lbl_ChooseRange}}
                                  </span>
                                  <ng-template [ngIf]="filter.isNull">
                                    <span class="font14 italic textGreyb9" style="padding-left: 5px;">(null)</span>
                                  </ng-template>
                                </div>
                              </div><br />
                              <div class="row">
                                <div class="col-6">
                                  <span class="p-float-label">
                                    <p-calendar [(ngModel)]="filter.range[0]" [showTime]="false" [showSeconds]="true"
                                      [showButtonBar]="true" [showIcon]="true" [dateFormat]="checkDateFormat()"
                                      class="font14" inputId="from_date"
                                      (contextmenu)="nullifyDateFilter(filter, header, $event)"></p-calendar>
                                    <label for="from_date"
                                      class="textGreyb9 font13">{{app.translations.GBL_lbl_RangeFrom}}</label>
                                  </span>
                                </div>
                                <div class="col-6">
                                  <span class="p-float-label">
                                    <p-calendar [(ngModel)]="filter.range[1]" [showTime]="false" [showSeconds]="true"
                                      [showButtonBar]="true" [showIcon]="true" [dateFormat]="checkDateFormat()"
                                      class="font14" inputId="to_date"
                                      (contextmenu)="nullifyDateFilter(filter, header, $event)"></p-calendar>
                                    <label for="to_date"
                                      class="textGreyb9 font13">{{app.translations.GBL_lbl_RangeTo}}</label>
                                  </span>
                                </div>
                              </div><br />
                              <div class="row">
                                <div class="col-12 flex justify-right">
                                  <button class="btn font17" (click)="createRangeFilter(filter)">
                                    <i class="fa-solid fa-check textGreen padding3 b-radius control-bar-btn-icon"></i>
                                    <span class="control-bar-btn-text"
                                      [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_btn_ApplyText}}</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </p-overlayPanel>
                      </td>
                    </ng-template>
                    <ng-template [ngIf]="header.displayFormat == 'DATETIME'">
                      <td (dblclick)="clearFilter(filter)" class="relative cursorPointer"
                        [ngClass]="['padding' + tablePadding, darkMode ? 'bg-dark' : 'bg-white']">
                        <button class="absolute bg-none unset-p unset-b range-filter-btn" tooltipPosition="top"
                          [ngClass]="darkMode ? 'textWhite' : 'textBlack'" (click)="op.toggle($event)"
                          pTooltip="{{getDateTimeFilterTooltip(filter)}}" [tooltipDisabled]="filter.range.length == 0">
                          <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                        </button>
                        <p-overlayPanel #op>
                          <ng-template pTemplate="content">
                            <div class="b-radius box-shadow" style="padding: 20px 0px 10px;"
                              [ngClass]="[darkMode ? 'bg-dark' : 'bg-white']">
                              <div class="row">
                                <div class="col-12">
                                  <span class="font15 padding5 b-radius"
                                    [ngClass]="[darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-light-blue']">
                                    {{app.translations.GBL_lbl_ChooseRange}}
                                  </span>
                                  <ng-template [ngIf]="filter.isNull">
                                    <span class="font13 textGreyb9 float-right">null</span>
                                  </ng-template>
                                </div>
                              </div><br />
                              <div class="row">
                                <div class="col-6">
                                  <span class="p-float-label">
                                    <p-calendar [(ngModel)]="filter.range[0]" [showTime]="true" [showSeconds]="true"
                                      [showButtonBar]="true" [showIcon]="true" [dateFormat]="checkDateFormat()"
                                      class="font14" inputId="from_date"
                                      (contextmenu)="nullifyDateFilter(filter, header, $event)"></p-calendar>
                                    <label for="from_date"
                                      class="textGreyb9 font13">{{app.translations.GBL_lbl_RangeFrom}}</label>
                                  </span>
                                </div>
                                <div class="col-6">
                                  <span class="p-float-label">
                                    <p-calendar [(ngModel)]="filter.range[1]" [showTime]="true" [showSeconds]="true"
                                      [showButtonBar]="true" [showIcon]="true" [dateFormat]="checkDateFormat()"
                                      class="font14" inputId="to_date"
                                      (contextmenu)="nullifyDateFilter(filter, header, $event)"></p-calendar>
                                    <label for="to_date"
                                      class="textGreyb9 font13">{{app.translations.GBL_lbl_RangeTo}}</label>
                                  </span>
                                </div>
                              </div><br />
                              <div class="row">
                                <div class="col-12 flex justify-right">
                                  <button class="btn font17" (click)="createRangeFilter(filter)">
                                    <i class="fa-solid fa-check textGreen padding3 b-radius control-bar-btn-icon"></i>
                                    <span class="control-bar-btn-text"
                                      [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_btn_ApplyText}}</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </ng-template>
                        </p-overlayPanel>
                      </td>
                    </ng-template>
                    <ng-template [ngIf]="header.displayFormat == 'CHECK'">
                      <td (dblclick)="clearFilter(filter)"
                        [ngClass]="['padding' + tablePadding, darkMode ? 'bg-dark' : 'bg-white']">
                        <mat-checkbox #chk [checked]="preSelectCheckBoxFilter(header, filter.field, filter.value)"
                          [indeterminate]="filter.isNull"
                          (change)="updateValue(chk.value, header.field, $event, filter)"
                          (contextmenu)="nullifyFilter(filter, header, $event)">
                        </mat-checkbox>
                      </td>
                    </ng-template>
                    <ng-template [ngIf]="header.displayFormat == '' || header.displayFormat == 'string' 
                    || header.displayFormat == 'IMAGE' || header.displayFormat == 'HTML'">
                      <td (dblclick)="clearFilter(filter)"
                        [ngClass]="['padding' + tablePadding, darkMode ? 'bg-dark' : 'bg-white']">
                        <input class="form-control b-radius input-filter" type="text" #txtFilter
                          [ngClass]="['font' + globalFontSize, 'padding' + tablePadding, darkMode ? 'textWhite' : 'textBlack']"
                          placeholder="..." (keyup)="updateValue(txtFilter.value, header.field, $event, filter)"
                          [disabled]="header.displayFormat == 'IMAGE' || filter.dataType == 'Byte[]'"
                          [value]="header.field == filter.field ? filter.value : ''"
                          (contextmenu)="nullifyFilter(filter, header, $event)" />
                      </td>
                    </ng-template>
                  </ng-template>
                </ng-container>
              </ng-container>
            </tr>
          </ng-template>
        </ng-template>
      </p-table>
    </div>
  </div>
  <ng-template [ngIf]="masterConfig && keyValue">
    <div class="row" style="margin-bottom: 10px !important;">
      <div class="col-12">
        <mat-tab-group class="detail-tab-group" [ngClass]="[darkMode ? 'bg-dark' : 'bg-white']"
          [(selectedIndex)]="selectedTabIndex" (selectedTabChange)="changeTab($event)" [@.disabled]="true">
          <ng-container *ngFor="let child of this.masterConfig.childGrids">
            <mat-tab class="relative animated fadeIn">
              <ng-template mat-tab-label>
                <div>
                  <span [ngClass]="darkMode ? 'textWhite' : 'textBlack'"
                    style="padding: 10px 30px;">{{child.caption}}</span>
                </div>
              </ng-template>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
      </div>
    </div>
  </ng-template>
  <div class="row" [class.disable-selection]="shiftSelect || controlSelect">
    <div [ngClass]="extendedData ? 'col-9' : 'col-3'"
      style="padding: 0px 5px !important; overflow-y: auto !important; max-height: 78vh;">
      <ng-template [ngIf]="lines.length > 0">
        <div class="row">
          <div class="col-10 unset-p">
            <span [ngClass]="darkMode ? 'textWhite' : 'textBlack'"
              class="font14 animated fadeIn padding10 record-count">
              {{lines.length}} {{app.translations.GBL_lbl_Rows}}
            </span>
          </div>
          <div class="col-2 unset-p flex justify-right">
            <button class="btn font14" (click)="extendData()" style="margin-bottom: 5px !important;">
              <i class="fa-solid padding3 b-radius textGreen"
                [ngClass]="extendedData ? 'fa-angles-left' : 'fa-angles-right'"></i>
            </button>
          </div>
        </div>
        <div class="row">
          <ng-container *ngFor="let line of lines">
            <div class="relative" [ngClass]="extendedData ? 'col-4' : 'col-12'">
              <div class="b-radius relative animated fadeIn transition4 font13 tile-container cursorPointer"
                style="margin-bottom: 10px; overflow-y: hidden; padding: 5px 25px 5px 5px;" [ngClass]="[darkMode ? (line.selected ? 'textWhite bg-green' : 'textWhite bg-dark-secondary') : 
                        (!line.selected ? 'textBlack bg-white' : 'textWhite bg-green')]"
                [ngStyle]="{'height': extendedData ? 'auto' : (line.expanded ? 'auto' : '110px')}"
                (click)="compileLines(line)">
                <ng-container *ngFor="let item of line.list | keyvalue">
                  <ng-container *ngFor="let header of headers">
                    <ng-template [ngIf]="header.visible && item.key.toString() == header.caption">
                      <div>
                        <span class="bold">{{ item.key }}</span><br>
                        <ng-template [ngIf]="item && (item.key != 'ICON' && item.key != 'IMAGE' 
                        && !item.value.toString().toLowerCase().includes('true') && !item.value.toString().toLowerCase().includes('false')
                        && !item.value.toString().includes('</') && !item.value.toString().includes('<br>'))">
                          <span>
                            <ng-template [ngIf]="item.value.length > 0">{{ item.value }}<br></ng-template>
                            <ng-template [ngIf]="item.value.length == 0">-<br></ng-template>
                          </span>
                        </ng-template>
                      </div>
                      <br>
                    </ng-template>
                  </ng-container>
                </ng-container>
              </div>
              <i class="fa-solid fa-chevron-down absolute font14 cursorPointer padding5" (click)="expandTile(line)"
                style="top: 0px; right: 8px; width: fit-content;"
                [ngClass]="[darkMode ? 'textWhite' : 'textBlack', extendedData ? 'display-none' : 'display-unset']"></i>
            </div>
          </ng-container>
        </div>
      </ng-template>
      <ng-template [ngIf]="lines.length == 0">
        <span class="font15 padding10 text-centre b-radius inline-block full-width no-records unset-m"
          [ngClass]="darkMode ? 'bg-dark-secondary textWhite' : 'bg-light-blue textBlack'">
          {{app.translations.GBL_lbl_NoRecords}}
        </span>
      </ng-template>
    </div>
    <div [ngClass]="extendedData ? 'col-3' : 'col-9'" style="padding: 0px 5px !important;">
      <div id="map" style="height: 78vh; width: 100%;"></div>
    </div>
  </div>
</div>