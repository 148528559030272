<ng-container *ngFor="let tab of tabs">
  <button (click)="changeTab(tab)"
    class="animated fadeIn font14 b-radius unset-outline unset-box-shadow rad-tab"
    [ngClass]="darkMode ? 'bg-dark-secondary textWhite' : 'bg-light-blue textBlack'"
    [ngStyle]="{'border': tab.selected ? (darkMode ? '2px solid #fff' : '2px solid var(--green)') : ('2px solid transparent')}">
    <span>{{tab.groupName}}</span>
  </button>
  <ng-template [ngIf]="tab.selected">
    <div class="animated fadeIn absolute box-shadow b-radius full-width overflow-auto rad-container"
      [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
      <ng-container *ngFor="let group of tab.data">
        <div class="row" style="margin-bottom: 5px !important;">
          <div class="col-12 cursorPointer b-radius padding5 rad-group-container"
            [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'" (click)="expandGroup(group);">
            <span class="font15 rad-group-title inline-block"
              [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
              {{group.key}}
            </span>
          </div>
          <ng-template [ngIf]="group.expanded">
            <ng-container *ngFor="let button of group.value">
              <div class="animated fadeIn rad-group b-radius unset-p">
                <ng-template [ngIf]="tab.groupName == app.translations.GBL_lbl_ActionsText">
                  <button class="font13 b-radius padding5" type="button" [disabled]="button.disabled"
                    (click)="loadAction(button)" [ngClass]="{'rad-btn-dark': darkMode && !button.disabled, 'rad-btn-light': !darkMode && !button.disabled, 
                    'rad-btn-disabled': button.disabled, 'rad-btn': !button.disabled, 
                    'textWhite bg-dark-secondary': darkMode, 'textBlack bg-light-blue': !darkMode}"
                    [ngStyle]="{'cursor': button.disabled ? 'not-allowed' : 'pointer', 'cursor': button.disabled ? 'not-allowed' : 'pointer'}">
                    <ng-template [ngIf]="button.imageIcon != null">
                      <img [src]="button.imageIcon" width="25" height="25" />
                    </ng-template>
                    {{button.caption}}
                  </button>
                </ng-template>
                <ng-template [ngIf]="tab.groupName == app.translations.GBL_lbl_ReportText">
                  <button class="font13 b-radius padding5" type="button" [disabled]="button.disabled"
                    (click)="loadReport(button)" [ngClass]="{'rad-btn-dark': darkMode && !button.disabled, 'rad-btn-light': !darkMode && !button.disabled, 
                    'rad-btn-disabled textGreyb9': button.disabled, 'rad-btn': !button.disabled, 
                    'textWhite bg-dark-secondary': darkMode, 'textBlack bg-light-blue': !darkMode}"
                    [ngStyle]="{'cursor': button.disabled ? 'not-allowed' : 'pointer'}">
                    <ng-template [ngIf]="button.icon != null">
                      <img [src]="'data:image/png;base64,' + button.icon" width="25" height="25" />
                    </ng-template>
                    {{button.name}}
                  </button>
                </ng-template>
                <ng-template [ngIf]="tab.groupName == app.translations.GBL_lbl_DashboardsText">
                  <button class="font13 b-radius padding5" type="button" [disabled]="button.disabled"
                    (click)="loadDashboard(button)" [ngClass]="{'rad-btn-dark': darkMode && !button.disabled, 'rad-btn-light': !darkMode && !button.disabled, 
                    'rad-btn-disabled': button.disabled, 'rad-btn': !button.disabled, 
                    'textWhite bg-dark-secondary': darkMode, 'textBlack bg-light-blue': !darkMode}"
                    [ngStyle]="{'cursor': button.disabled ? 'not-allowed' : 'pointer'}">
                    <ng-template [ngIf]="button.icon != null">
                      <img [src]="'data:image/png;base64,' + button.icon" width="25" height="25" />
                    </ng-template>
                    {{button.name}}
                  </button>
                </ng-template>
              </div>
            </ng-container>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </ng-template>
</ng-container>