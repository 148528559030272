<p-dialog [visible]="true" [breakpoints]="{'430px': '100%'}" [style]="{width: '40%'}" [baseZIndex]="10000" [modal]="true" [maximizable]="false"
  [draggable]="false" [resizable]="false"
  [ngClass]="{'change-scrollbar-color-darkMode': darkMode, 'change-scrollbar-color': !darkMode}">
  <div class="row sticky dialog-title-container" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-12 unset-p">
      <div class="flex relative align-centre dialog-title">
        <span class="textGreen font16 b-radius dialog-title-icon" [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
          <i class="fa-solid fa-pen"></i>
        </span>
        <span class="font17" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
          {{app.translations.COL_lbl_Title}}
        </span>
        <div class="absolute" style="right: 15px;">
          <span class="textGreen font16 b-radius dialog-title-icon cursorPointer" (click)="close()"
            [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
            <i class="fa-solid fa-xmark"></i>
          </span>          
        </div>
      </div>
    </div>
  </div>
  <div class="row" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-12 animated fadeIn flex justify-left padding15">
      <mat-checkbox [checked]="false" (change)="updateAll()">
        <span [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
          {{app.translations.COL_lbl_CheckAll}}
        </span>
      </mat-checkbox>
    </div>
    <ng-container *ngFor="let header of headers">
      <div class="col-12 animated fadeIn flex justify-left padding15">
        <div class="p-field-checkbox">
          <mat-checkbox [checked]="header.visible" (change)="update(header.caption, $event)">
            <span [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
              {{header.caption}}
            </span>
          </mat-checkbox>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="row sticky dialog-footer-container" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-12 unset-p">
      <div class="flex justify-right padding10 dialog-footer">
        <button class="btn font17" (click)="save()">
          <i class="fa-solid fa-floppy-disk textGreen"></i>
          <span [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_btn_SaveText}}</span>
        </button>
      </div>
    </div>
  </div>
</p-dialog>