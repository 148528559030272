import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import { header } from 'app/_misc/headers';

@Injectable({ providedIn: 'root' })
export class GanttService {
  constructor(private httpClient: HttpClient) { }  

  getData(formData: FormData) {
    return this.httpClient.post('/gantt/getdata', formData, { 
      headers: new header().FormHeader, observe: 'response' 
    }).pipe(map(response => {       
      return response.body; 
    }), catchError(this.handleError));
  }

  handleError(error: any) {
    return throwError(error);
  }
}