import { Injectable } from "@angular/core";
import { IFilter } from "app/_models/IFilter";
import { IHeader } from "app/_models/IHeader";
import { DatePipe } from "@angular/common";
import { AppComponent } from "app/app.component";

@Injectable({ providedIn: 'root' })
export class SortFilterController {
  constructor(
    private app: AppComponent,
    private datePipe: DatePipe,
  ) { }

  loadFilters(headers: IHeader[]): IFilter[] {
    let filters = [];
    headers.forEach(header => {
      if (header.dataType == null) header.dataType = '';
      if (header.displayFormat == null) header.displayFormat = '';

      if (header.visible) {
        let format = 'string'
        if ((header.caption.toLowerCase().includes('time') && header.dataType.toLowerCase() == 'timespan')) format = 'TIME';
        if (header.dataType.toLowerCase() == 'datetime' && header.displayFormat == '') format = 'DATETIME';
        if ((header.caption.toLowerCase().includes('date') || header.dataType.toLowerCase() == 'datetime')) format = 'DATE';
        if (header.dataType.toLowerCase() == 'boolean') format = 'CHECK';
        if (header.dataType == 'Byte[]') format = 'IMAGE';

        filters.push({
          caption: header.caption,
          field: header.field,
          value: '',
          isNull: false,
          dataType: header.dataType,
          displayFormat: format,
          isActive: false,
          range: []
        });
      }
    })

    return filters;
  }

  nullifyFilter(filter: IFilter, header: IHeader): IFilter {
    filter.isNull = true;
    filter.isActive = true;
    filter.displayFormat = header.displayFormat;

    if (header.displayFormat == 'CHECK') filter.value = 'null||CHECK';
    if (header.displayFormat == 'DATE' || header.displayFormat == 'DATETIME') filter.value = 'null||DATE';
    if (header.displayFormat == 'TIME') filter.value = 'null||TIME';
    if (header.displayFormat == '' || header.displayFormat == 'string') filter.value = 'null';

    return filter;
  }

  buildFilterString(val: any, col: string, filters: IFilter[], nodeId: number, isNull: boolean, detailView: boolean, rowId: string, tabName: string): string {
    //#region Cookies   
    if (!detailView) {
      //set cookies for main grid filters
      if (typeof val == 'string' && val.includes('|RANGE|')) {
        let type = val.split(';')[0];
        let from = val.split(';')[1].split('|RANGE|')[0];
        let to = val.split(';')[1].split('|RANGE|')[1];
        this.app.cookieService.set(`filter${nodeId}|${col}`, `type@${type}|RANGEFROM;${from}|RANGETO;${to}`, 365, '/', '', false);
      } else if (isNull && val == 'null') {
        this.app.cookieService.set(`filter${nodeId}|${col}`, `${val}||string`, 365, '/', '', false);
      } else if (isNull && val.includes('null')) {
        this.app.cookieService.set(`filter${nodeId}|${col}`, val, 365, '/', '', false);
      } else if (typeof val == 'boolean' && val) {
        this.app.cookieService.set(`filter${nodeId}|${col}`, `1$bool`, 365, '/', '', false);
      } else if (typeof val == 'boolean' && !val) {
        this.app.cookieService.set(`filter${nodeId}|${col}`, `0$bool`, 365, '/', '', false);
      } else {
        this.app.cookieService.set(`filter${nodeId}|${col}`, `${val}`, 365, '/', '', false);
      }

      if (typeof val == 'string' && val.trim() == '') {
        this.app.cookieService.delete(`filter${nodeId}|${col}`);
      }
    } else {
      //set cookies for detail grid filters
      let cookieName = `detailfilter${nodeId}|${col};${tabName}`;
      if (this.app.cookieService.get('RowFilters') == 'true') {
        //if filters need to save by row
        cookieName = `detailfilter${nodeId}|${col};${tabName}|${rowId}|${this.app.datePipe.transform(new Date(), 'yyyy-MM-dd')}`;
      }

      if (typeof val == 'string' && val.includes('|RANGE|')) {
        let type = val.split(';')[0];
        let from = val.split(';')[1].split('|RANGE|')[0];
        let to = val.split(';')[1].split('|RANGE|')[1];
        this.app.cookieService.set(cookieName, `type@${type}|RANGEFROM;${from}|RANGETO;${to}`, 365, '/', '', false);
      } else if (isNull && val == 'null') {
        this.app.cookieService.set(cookieName, `${val}||string`, 365, '/', '', false);
      } else if (isNull && val.includes('null')) {
        this.app.cookieService.set(cookieName, val, 365, '/', '', false);
      } else if (typeof val == 'boolean' && val) {
        this.app.cookieService.set(cookieName, `1$bool`, 365, '/', '', false);
      } else if (typeof val == 'boolean' && !val) {
        this.app.cookieService.set(cookieName, `0$bool`, 365, '/', '', false);
      } else {
        this.app.cookieService.set(cookieName, `${val}`, 365, '/', '', false);
      }

      if (typeof val == 'string' && val.trim() == '') {
        this.app.cookieService.delete(cookieName);
      }
    }
    //#endregion 

    let filterString = '';
    if (filters.length > 0) {
      filters.forEach(filter => {
        if (filter.field == col) filter.value = val;

        if (typeof filter.value == 'string' && filter.field == col && val.includes('|RANGE|') && !filter.isNull) {
          let from = val.split(';')[1].split('|RANGE|')[0];
          let to = val.split(';')[1].split('|RANGE|')[1];
          let startFormatted = this.datePipe.transform(new Date(from), 'yyyy-MM-dd HH:mm:ss');
          let endFormatted = this.datePipe.transform(new Date(to), 'yyyy-MM-dd HH:mm:ss');
          let rangeFilters = ` and ${filter.field} >= '${startFormatted}' and ${filter.field} <= '${endFormatted}'`;
          filterString += rangeFilters;
          filter.value = from;
        } else if (filter.isNull) {
          filterString += ` and ${filter.field} like '%null%'`;
        } else if (filter.range.length > 0) {
          let startFormatted = this.datePipe.transform(new Date(filter.range[0]), 'yyyy-MM-dd HH:mm:ss');
          let endFormatted = this.datePipe.transform(new Date(filter.range[1]), 'yyyy-MM-dd HH:mm:ss');
          let rangeFilters = ` and ${filter.field} >= '${startFormatted}' and ${filter.field} <= '${endFormatted}'`;
          filterString += rangeFilters;
        } else if (typeof filter.value == 'string' && filter.value != '') {
          filterString += ` and ${filter.field} like '%${filter.value.trim()}%'`;
        }

        if (typeof filter.value == 'boolean') {
          filterString += ` and ${filter.field} like '%${filter.value ? 1 : 0}%'`;
        }
      })
    }

    return filterString;
  }

  createRangeFilter(from: string, to: string, filter: IFilter): IFilter {
    if (filter.displayFormat == 'DATETIME' || filter.displayFormat == 'DATE') {
      filter.isActive = true;
      filter.value = `type@${filter.displayFormat}|RANGEFROM;${new Date(from)}|RANGETO;${new Date(to)}`;
      filter.range = [];
      filter.range.push(new Date(from));
      filter.range.push(new Date(to));
      return filter;
    }

    //if not date or datetime then just time
    let start = from;
    let timeValues = start.split(':');
    let addedHour = (Number(timeValues[0]) + 1).toString();
    if (addedHour == '25') addedHour = '00';
    if (addedHour.length == 1) addedHour = '0' + addedHour;
    let end = `${addedHour}:${timeValues[1]}:${timeValues[2]}`;
    filter.isActive = true;
    filter.value = start;
    filter.range = [];
    filter.range.push(start);
    filter.range.push(end);
    return filter;
  }

  clearFilter(filterToClear: IFilter, filters: IFilter[]): string {
    //removes a filter from the filterstring
    let filterString = '';
    filterToClear.value = '';
    filterToClear.isNull = false;
    filterToClear.isActive = false;
    filterToClear.range = [];

    if (filters.length > 0) {
      filters.forEach(filter => {
        if (filter.isNull) {
          filterString += ` and ${filter.field} like '%null%'`;
        } else if (filter.range.length > 0) {
          let startFormatted = this.datePipe.transform(new Date(filter.range[0]), 'yyyy-MM-dd HH:mm:ss');
          let endFormatted = this.datePipe.transform(new Date(filter.range[1]), 'yyyy-MM-dd HH:mm:ss');
          let rangeFilters = ` and ${filter.field} >= '${startFormatted}' and ${filter.field} <= '${endFormatted}'`;
          filterString += rangeFilters;
        } else if (typeof filter.value == 'string' && filter.value != '') {
          filterString += ` and ${filter.field} like '%${filter.value.trim()}%'`;
        }

        if (typeof filter.value == 'boolean') {
          filterString += ` and ${filter.field} like '%${filter.value ? 1 : 0}%'`;
        }
      })
    }

    return filterString;
  }

  clearAllFilters(filters: IFilter[], nodeId: string, tabName: string, rowId?: string): IFilter[] {
    let cookies = this.app.cookieService.getAll();

    //clear all main grid filters
    if (tabName == 'master') {
      filters.forEach(filter => {
        for (let cookie in cookies) {
          if (cookie.includes(`filter${nodeId}|${filter.field}`) && cookies.hasOwnProperty(cookie)) {
            this.app.cookieService.delete(cookie);
          }
        }

        filter.value = '';
        filter.isActive = false;
        filter.isNull = false;
        filter.range = [];
      })

      return filters;
    }

    //clears all detail filters by tab if mode is global
    if (!this.app.cookieService.check('RowFilters') || this.app.cookieService.get('RowFilters') == 'false') {
      filters.forEach(filter => {
        for (let cookie in cookies) {
          if (cookie.includes(`detailfilter${nodeId}|${filter.field};${tabName}`) && cookies.hasOwnProperty(cookie)) {
            this.app.cookieService.delete(cookie);
          }
        }

        filter.value = '';
        filter.isActive = false;
        filter.isNull = false;
        filter.range = []
      })

      return filters;
    }

    //clears all detail filters by tab by row if mode is not global    
    filters.forEach(filter => {
      for (let cookie in cookies) {
        if (cookie.includes(`detailfilter${nodeId}|${filter.field};${tabName}|${rowId}`) && cookies.hasOwnProperty(cookie)) {
          this.app.cookieService.delete(cookie);
        }
      }

      filter.value = '';
      filter.isActive = false;
      filter.isNull = false;
      filter.range = []
    })

    return filters;
  }

  checkIndeterminate(value: any): boolean {
    if (typeof value == 'boolean') return false;
    return true;
  }

  preSelectCheckBoxFilter(header: IHeader, filterField: string, filterValue: string): boolean {
    if (header.field == filterField) return this.app.tryParseBoolean(filterValue);
    return false;
  }

  getDateFilterTooltip(filter: IFilter, dateFormat: string): string {
    let originalDate = new Date(filter.range[0]);
    let originalDate2 = new Date(filter.range[1]);
    let year = originalDate.getFullYear();
    let month = String(originalDate.getMonth() + 1).padStart(2, '0');
    let day = String(originalDate.getDate()).padStart(2, '0');
    let year2 = originalDate2.getFullYear();
    let month2 = String(originalDate2.getMonth() + 1).padStart(2, '0');
    let day2 = String(originalDate2.getDate()).padStart(2, '0');
    if (dateFormat.includes('-')) return `${year}-${month}-${day} to ${year2}-${month2}-${day2}`;
    return `${year}/${month}/${day} to ${year2}/${month2}/${day2}`;
  }

  getDateTimeFilterTooltip(filter: IFilter, dateFormat: string): string {
    let originalDate = new Date(filter.range[0]);
    let originalDate2 = new Date(filter.range[1]);
    let year = originalDate.getFullYear();
    let month = String(originalDate.getMonth() + 1).padStart(2, '0');
    let day = String(originalDate.getDate()).padStart(2, '0');
    let hour = String(originalDate.getHours()).padStart(2, '0');
    let minute = String(originalDate.getMinutes()).padStart(2, '0');
    let second = String(originalDate.getSeconds()).padStart(2, '0');
    let year2 = originalDate2.getFullYear();
    let month2 = String(originalDate2.getMonth() + 1).padStart(2, '0');
    let day2 = String(originalDate2.getDate()).padStart(2, '0');
    let hour2 = String(originalDate2.getHours()).padStart(2, '0');
    let minute2 = String(originalDate2.getMinutes()).padStart(2, '0');
    let second2 = String(originalDate2.getSeconds()).padStart(2, '0');
    if (dateFormat.includes('-')) return `${year}-${month}-${day} ${hour}:${minute}:${second} to ${year2}-${month2}-${day2} ${hour2}:${minute2}:${second2}`;
    return `${year}/${month}/${day} ${hour}:${minute}:${second} to ${year2}/${month2}/${day2} ${hour2}:${minute2}:${second2}`;
  }

  buildFilterStringFromCookies(nodeId: string, detail: boolean, rowId?: string, tabName?: string): string {
    let filterString = '';
    let cookies = this.app.cookieService.getAll();
    for (const cookie in cookies) {
      if (cookie.includes('filter')) {
        let cookieNodeId = cookie.split('|')[0].split('filter')[1] == undefined ? 0 : cookie.split('|')[0].split('filter')[1];
        if (cookie.split('|').length == 2 && !detail && !cookie.includes('detail')) {
          //build main grid filter string from cookies
          if (cookies.hasOwnProperty(cookie) && cookieNodeId == nodeId) {
            let cookieName = cookie.split('|')[1];
            let cookieValue = cookies[cookie];

            if (cookieValue.includes('$bool')) {
              let cookieValues = cookieValue.split('$bool');
              filterString = filterString + ` and ${cookieName} like '%${cookieValues[0]}%'`;
            } else if (cookieValue.includes('RANGE')) {
              let rangeFrom = cookieValue.split('|')[1].split(';')[1];
              let rangeTo = cookieValue.split('|')[2].split(';')[1];
              let startFormatted = this.datePipe.transform(new Date(rangeFrom), 'yyyy-MM-dd HH:mm:ss');
              let endFormatted = this.datePipe.transform(new Date(rangeTo), 'yyyy-MM-dd HH:mm:ss');
              filterString = filterString + ` and ${cookieName} >= '${startFormatted}' and ${cookieName} <= '${endFormatted}'`;
            } else if (cookieName.toLowerCase().includes('date')) {
              filterString = filterString + ` and ${cookieName} = '${cookieValue}'`;
            } else if (cookieValue.includes('null')) {
              filterString = filterString + ` and ${cookieName} like '%null%'`;
            } else {
              filterString = filterString + ` and ${cookieName} like '%${cookieValue.trim()}%'`;
            }
          }
        } else if (!this.app.cookieService.check('RowFilters') || this.app.cookieService.get('RowFilters') == 'false') {
          //build detail grid filterstring from cookies if detail filter mode is global
          if (cookie.includes('detail') && cookie.split('|').length == 2 && detail && tabName == cookie.split('|')[1].split(';')[1]) {
            if (cookies.hasOwnProperty(cookie) && cookieNodeId == nodeId) {
              let cookieName = cookie.split('|')[1].split(';')[0];
              let cookieValue = cookies[cookie];

              if (cookieValue.includes('$bool')) {
                let cookieValues = cookieValue.split('$bool');
                filterString = filterString + ` and ${cookieName} like '%${cookieValues[0]}%'`;
              } else if (cookieValue.includes('RANGE')) {
                let rangeFrom = cookieValue.split('|')[1].split(';')[1];
                let rangeTo = cookieValue.split('|')[2].split(';')[1];
                let startFormatted = this.app.datePipe.transform(new Date(rangeFrom), 'yyyy-MM-dd HH:mm:ss');
                let endFormatted = this.app.datePipe.transform(new Date(rangeTo), 'yyyy-MM-dd HH:mm:ss');
                filterString = filterString + ` and ${cookieName} >= '${startFormatted}' and ${cookieName} <= '${endFormatted}'`;
              } else if (cookieName.toLowerCase().includes('date')) {
                filterString = filterString + ` and ${cookieName} = '${cookieValue}'`;
              } else if (cookieValue.includes('null')) {
                filterString = filterString + ` and ${cookieName} like '%null%'`;
              } else {
                filterString = filterString + ` and ${cookieName} like '%${cookieValue.trim()}%'`;
              }
            }
          }
        } else if (this.app.cookieService.get('RowFilters') == 'true') {
          //build detail grid filterstring from cookies if detail filter mode is NOT global
          if (cookie.includes('detail') && cookie.split('|').length == 4 && detail && tabName == cookie.split('|')[1].split(';')[1] && rowId == cookie.split('|')[2]) {
            if (cookies.hasOwnProperty(cookie) && cookieNodeId == nodeId) {
              let cookieName = cookie.split('|')[1].split(';')[0];
              let cookieValue = cookies[cookie];

              if (cookieValue.includes('$bool')) {
                let cookieValues = cookieValue.split('$bool');
                filterString = filterString + ` and ${cookieName} like '%${cookieValues[0]}%'`;
              } else if (cookieValue.includes('RANGE')) {
                let rangeFrom = cookieValue.split('|')[1].split(';')[1];
                let rangeTo = cookieValue.split('|')[2].split(';')[1];
                let startFormatted = this.app.datePipe.transform(new Date(rangeFrom), 'yyyy-MM-dd HH:mm:ss');
                let endFormatted = this.app.datePipe.transform(new Date(rangeTo), 'yyyy-MM-dd HH:mm:ss');
                filterString = filterString + ` and ${cookieName} >= '${startFormatted}' and ${cookieName} <= '${endFormatted}'`;
              } else if (cookieName.toLowerCase().includes('date')) {
                filterString = filterString + ` and ${cookieName} = '${cookieValue}'`;
              } else if (cookieValue.includes('null')) {
                filterString = filterString + ` and ${cookieName} like '%null%'`;
              } else {
                filterString = filterString + ` and ${cookieName} like '%${cookieValue.trim()}%'`;
              }
            }
          }
        }
      }
    }

    return filterString;
  }

  populateFiltersFromCookies(filters: IFilter[], headers: IHeader[], cookie: string, cookies: any): { filters: IFilter[], headers: IHeader[] } {
    let cookieField = cookie.split('|')[1].split(';')[0];
    let cookieValue = cookies[cookie];
    filters.forEach(filter => {
      if (cookieValue.includes('RANGE') && cookieValue.includes('type@') && filter.field == cookieField) {
        let type = cookieValue.split('|')[0].split('@')[1];
        filter.displayFormat = type;
        filter.range.push(new Date(cookieValue.split('|')[1].split(';')[1]));
        filter.range.push(new Date(cookieValue.split('|')[2].split(';')[1]));
        filter.value = cookieValue.split('|')[1].split(';')[1];
      }

      if (cookieValue.includes('null') && filter.field == cookieField) {
        filter.value = 'null';
        filter.isNull = true;
        filter.displayFormat = cookieValue.split('||')[1];
      }

      if (typeof cookieField == 'string' && filter.field == cookieField.trim()) {
        filter.value = cookieValue;
        if (filter.value.includes('||string')) {
          filter.value = filter.value.split('||')[0];
        }
      }

      if (cookieValue.includes('$bool') && filter.field == cookieField) {
        let cookieValues = cookieValue.split('$bool');
        if (cookieValues[0] == '0') filter.value = 'false';
        if (cookieValues[0] == '1') filter.value = 'true';
      }

      if (filter.dataType.toLowerCase() == 'boolean') filter.displayFormat = 'CHECK';
    })

    headers.forEach(header => {
      filters.forEach(filter => {
        if (header.caption == filter.caption) {
          header.displayFormat = filter.displayFormat == 'string' ? '' : filter.displayFormat;
        }
      })
    })

    return { filters, headers };
  }

  checkFilterCookies(): void {
    //remove excess detail filters after a week based on when the filter was created
    let cookies = this.app.cookieService.getAll();
    for (let cookie in cookies) {
      if (cookie.split('|').length == 4) {
        let cookieDate = this.datePipe.transform(new Date(cookie.split('|')[3]), 'yyyy-MM-dd');
        let diff = new Date().getDay() - new Date(cookieDate).getDay();
        if (diff > 7) this.app.cookieService.delete(cookie);
      }
    }
  }

  reloadHeaderDisplayFormats(filters: IFilter[], headers: IHeader[]): IHeader[] {
    headers.forEach(header => {
      if (header.dataType == 'HTML') header.displayFormat = 'HTML';
      let filter = filters.find(filter => filter.caption == header.caption);
      if (filter) {
        header.displayFormat = filter.displayFormat == 'string' ? '' : filter.displayFormat;
      }
    })

    return headers;
  }
}