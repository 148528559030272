import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ErrorService } from "app/_services/error.service";

@Injectable({ providedIn: 'root' })
export class ErrorController {
  constructor(    
    private router: Router,
    private errorService: ErrorService) {
  }

  logError(errorLog: any, functionName: string): void {
    if(errorLog != undefined && typeof errorLog == 'string' && errorLog.includes('Server Error')) {
      sessionStorage.setItem('errorRefNumber', errorLog.split('Ref:')[1].split(',')[0]);
      sessionStorage.setItem('error', errorLog.split('|')[1]);
      this.router.navigate(["error"]);
      return;
    }

    this.handleError(errorLog.message, functionName).then(result => {
      sessionStorage.setItem('errorRefNumber', result);
      sessionStorage.setItem('error', errorLog);
      this.router.navigate(["error"]);
    })
  }

  handleError(error: any, functionName: string): Promise<string> {
    let nodeId = -1;
    if (sessionStorage.getItem('nodeId')) {
      nodeId = Number(sessionStorage.getItem('nodeId')!.toString());
    }

    let userId = -1;
    if (sessionStorage.getItem('userId')) {
      userId = Number(sessionStorage.getItem('userId')!.toString());
    }

    let nodeName = 'Unknown View';
    if (sessionStorage.getItem('nodeName')) {
      nodeName = sessionStorage.getItem('nodeName')!.toString();
    }

    console.log(`UserId: ${userId}`);
    console.log(`NodeId: ${nodeId}`);
    console.log(`Path: ${nodeName} > ${functionName}`);

    return new Promise<string>((resolve) => {     
      let formData = new FormData();
      formData.append('userId', encodeURIComponent(userId.toString()));
      formData.append('nodeId', encodeURIComponent(nodeId.toString()));
      formData.append('nodeName', encodeURIComponent(nodeName));
      formData.append('functionName', encodeURIComponent(functionName));
      formData.append('error', encodeURIComponent(error.toString()));
      this.errorService.logError(formData).subscribe({
        next: (data: any) => {          
          resolve(data.result);
        }
      });
    });
  }
}