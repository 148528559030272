import { Component, OnInit, Input, HostListener } from '@angular/core';
import { IParameter } from 'app/_models/IParameter';
import { formatDate } from 'devextreme/localization';
import { AppComponent } from 'app/app.component';
import { ICompiledLine } from 'app/_models/ICompiledLine';
import { CommunicationService } from 'app/_services/communication.service';
import { IParentParameter } from 'app/_models/IParentParameter';
import { IAction } from 'app/_models/IAction';
import { RADController } from 'app/_controllers/radController';
import { IHeader } from 'app/_models/IHeader';
import { IGanttTask } from 'app/_models/IGanttTask';
import { ISourceViewHeader } from 'app/_models/ISourceViewHeader';

@Component({
  selector: 'form-action-component',
  templateUrl: './form-action.component.html',
  styleUrls: ['./form-action.component.css']
})
export class FormActionComponent implements OnInit {
  //#region Fields
  @Input() headers: IHeader[] | ISourceViewHeader[] = [];
  @Input() compiledItems: ICompiledLine[] | IGanttTask[] = [];
  @Input() action: IAction;
  @Input() nodeId: string;
  @Input() userId: string;
  @Input() username: string;
  @Input() darkMode: boolean;
  parameters: IParameter[] = [];
  childParams: IParameter[] = [];
  parentValues: IParentParameter[] = [];
  showConfirmation: boolean = false;
  updated: boolean = false;
  result: string;
  j: number;
  sqlError: string = '';
  invalidValues: boolean = false;
  sourceViewHeadersUsed: boolean = false;
  //#endregion

  constructor(
    public app: AppComponent,
    private communicationService: CommunicationService,
    private radController: RADController,
  ) { }

  ngOnInit(): void {
    this.parameters = this.action.parameters;

    if (this.headers) {
      this.j = 0;
      this.loadSourceViewHeaders();
      return;
    }

    this.initialise();
  }

  loadSourceViewHeaders(): void {
    let param = this.parameters[this.j];
    if (param != undefined && param.sourceView && this.j < this.parameters.length) {
      this.app.showLoader();
      this.radController.getSourceViewHeaders(this.nodeId, this.action.name, param.sourceView).then(result => {
        param.sourceViewHeaders = result;
        this.sourceViewHeadersUsed = true;
        this.j++;
        this.loadSourceViewHeaders();
      })
    } else if (this.j == this.parameters.length) {
      this.app.hideLoader();
      this.initialise();
    } else {
      this.j++;
      this.loadSourceViewHeaders();
    }
  }

  initialise() {
    this.childParams = this.parameters.filter(param => param.filterParameter != null && param.filterParameter.length > 0);
    if (this.compiledItems.length == 1 && this.action.prePopulate) {
      this.parameters.forEach(param => {
        if (param.preselectedValue == undefined || param.preselectedValue == '-') param.preselectedValue = '';

        let firstElement = this.compiledItems[0];
        if ('lineData' in firstElement) {
          for (let key of Object.keys((firstElement as ICompiledLine).lineData)) {
            if (param.caption.localeCompare(key, undefined, { sensitivity: 'accent' }) == 0 || (param.sourceColumn && param.sourceColumn == key)) {
              param.preselectedValue = (firstElement as ICompiledLine).lineData[key];
              param.value = (firstElement as ICompiledLine).lineData[key];

              if (param.type == 'date' || param.type == 'datebox')
                param.preselectedValue = formatDate(new Date(param.preselectedValue), 'yyyy-MM-dd');

              if (param.type == 'check' || param.type == 'checkbox')
                param.preselectedValue = this.app.tryParseBoolean(param.preselectedValue);

              //get rid of some formatting artifacts
              if (param.type == 'textarea' && param.preselectedValue.length > 0) {
                param.preselectedValue = param.preselectedValue.replace(/\\n/g, '\n');
                param.preselectedValue = param.preselectedValue.replace(/\\r/g, '');
                param.preselectedValue = param.preselectedValue.replace(/\\\\/g, '\\');
              }
            } 
          }
        } else {
          for (let key of Object.keys((firstElement as IGanttTask))) {
            if (param.caption.localeCompare(key, undefined, { sensitivity: 'accent' }) == 0) {
              param.preselectedValue = (firstElement as IGanttTask)[key];
              param.value = (firstElement as IGanttTask)[key];

              if (param.type == 'date' || param.type == 'datebox' || param.type == 'datetimebox')
                param.preselectedValue = formatDate(new Date(param.preselectedValue), 'yyyy-MM-dd');
            }
          }
        }
      })
    }

    this.parameters.forEach(param => {
      if ((param.type == 'check' || param.type == 'checkbox') && param.preselectedValue == '' && param.value == '') {
        param.preselectedValue = 'false';
      }

      if (param.type == 'combolookup' && param.data.includes('@USERID')) {
        param.data = param.data.replace('@USERID', this.userId);
      }

      //flag parameters that are parents of other parameters based on the filter
      param.isParentLookup = false;
      this.childParams.forEach(childParam => {
        if (param.caption == childParam.filterParameter) {
          param.isParentLookup = true;
          childParam.filterValue = param.preselectedValue;
        }
      })

      //assign parameter datatype based on headers datatypes      
      let firstElement = this.compiledItems[0];
      if (firstElement && 'lineData' in firstElement) {
        param.dataType = '';
        let headersToCheck = this.sourceViewHeadersUsed ? param.sourceViewHeaders : this.headers;
        headersToCheck.forEach(header => {
          if (param.caption === header.caption) {
            param.dataType = header.dataType;
          }
        });
      } else {
        this.headers.forEach(header => {
          if (param.caption === header.caption || header.caption.includes(param.caption)) {
            param.dataType = header.dataType;
          }
        });
      }
    })

    this.parameters.forEach(param => {
      if (!param.dataType) {
        if (param.type.includes('date')) param.dataType = 'DateTime';
        if (param.type.includes('text')) param.dataType = 'String';        
      }
    })    
    
    this.j = 0;
    this.loadLookups(false);
  }

  loadLookups(fromTextArea: boolean): void {
    if (this.j < this.parameters.length) {
      let param = this.parameters[this.j];
      if (param.type == 'combolookup') {
        param.lookupValues = [];
        let id = this.compiledItems.length > 0 ? this.compiledItems[0].id : ''
        this.radController.getLookupData(param.data, param.filterValue, id).then(result => {
          this.sqlError = '';
          if (result != undefined && result.length > 0 && result[0].id.includes('ERROR')) {
            if (result[0].value.includes('temp')) {
              this.radController.getLookupData(param.data, param.filterValue, id).then(result => {
                param.lookupValues = result;
              });
            } else if (fromTextArea) {
              this.sqlError = result[0].value;
            }
          } else {
            param.lookupValues = result;
          }
        });
      }

      if (param.type == 'combo' || param.type == 'combobox') {
        param.lookupValues = param.data.split(',');
      }

      this.j++;
      this.loadLookups(fromTextArea);
    } else if (this.j == this.parameters.length) {
      return;
    } else {
      this.j++;
      this.loadLookups(fromTextArea);
    }
  }

  updateCombos(parameter: IParameter, event: any): void {
    parameter.value = 'null';
    parameter.preselectedValue = 'null';
    if (event.srcElement.value.toString() != '0') {
      parameter.value = event.srcElement.value.toString();
      parameter.preselectedValue = event.srcElement.value.toString();
    }
    this.updated = true;
    if (!parameter.isParentLookup) return;

    this.childParams.forEach(childParam => {
      if (childParam.filterParameter == parameter.caption) {
        childParam.filterValue = parameter.value;
      }
    })

    this.j = 0;
    this.loadLookups(false);
  }

  loadLookupsFromTextArea(parameter: IParameter): void {
    if (!parameter.isParentLookup) return;

    this.childParams.forEach(childParam => {
      if (childParam.filterParameter == parameter.caption) {
        childParam.filterValue = parameter.value;
      }
    })

    this.j = 0;
    this.loadLookups(true);
  }

  updateTextInputs(parameter: IParameter, event: any): void {
    parameter.value = event.srcElement.value.toString();
    parameter.preselectedValue = event.srcElement.value.toString();
    this.updated = true;
  }

  updateCheckboxToggles(parameter: IParameter, event: any): void {
    parameter.value = event.checked;
    parameter.preselectedValue = event.checked;
    this.updated = true;
  }

  updateImage(parameter: IParameter, event: any): void {
    let fileToUpload = event.target.files;
    let file = fileToUpload[0] as File;

    if (!file.name.includes('.png')) {
      this.app.alertError(this.app.translations.ALERT_Error_InvalidImage);
      return;
    }

    this.updated = true;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = reader.result as string;
      parameter.value = base64String.replace('data:image/png;base64,', '');
      parameter.preselectedValue = base64String;
    };
  }

  updateNumericInputs(parameter: IParameter, event: any): void {
    if (parameter.dataType == 'Int32') {
      let value = event.srcElement.value.toString();
      if (value.includes(',') || value.includes('.')) {
        this.app.alertError(`${this.app.translations.ALERT_Error_InvalidDecimal} ${parameter.caption}`);
        this.invalidValues = true;
        return;
      }
    }

    this.invalidValues = false;
    parameter.value = event.srcElement.value.toString();
    parameter.preselectedValue = event.srcElement.value.toString();
    this.updated = true;
  }

  save(): void {
    if (this.invalidValues) {
      this.app.alertInfo(this.app.translations.ALERT_Error_InvalidValues);
      return;
    }

    if (!this.updated && !this.action.prePopulate) {
      this.app.alertInfo(this.app.translations.ALERT_Info_NothingToSave);
      return;
    }

    this.app.showLoader();
    for (let i = 0; i <= this.parameters.length; i++) {
      if (i == this.parameters.length) {        
        this.executeSave();
      } else {
        let param = this.parameters[i];
        if (param.type == 'image' || param.type == 'imagebox') {
          if (param.value != 'null') {
            param.value = param.value.toString().replace('SafeValue must use [property]=binding: ', '');
            param.value = param.value.toString().replace(' (see https://g.co/ng/security#xss)', '');
            param.value = param.value.toString().replace('{\"changingThisBreaksApplicationSecurity\":', '');
            param.value = param.value.toString().replace('}', '');
          }

          if (param.preselectedValue != undefined || param.preselectedValue != '') {
            param.preselectedValue = param.preselectedValue.toString().replace('{\"changingThisBreaksApplicationSecurity\":', '');
            param.preselectedValue = param.preselectedValue.toString().replace('}', '');
            param.preselectedValue = param.preselectedValue.toString().replace('SafeValue must use [property]=binding: ', '');
            param.preselectedValue = param.preselectedValue.toString().replace(' (see https://g.co/ng/security#xss)', '');
          }
        }
      }
    }
  }

  executeSave(): void {
    if (this.compiledItems.length <= 1) {
      //if 1 or 0 lines are selected you're either creating a new record or updating a single existing one    
      //both ICompiledLine and IGanttTask has a field called id  
      let id = this.compiledItems.length == 1 ? this.compiledItems[0].id : '';
      this.radController.createUpdateSingleRecord(this.nodeId, this.userId, this.action.name, id, this.parameters).then(result => {
        this.handleSaveResult(result);
      });

      return;
    }

    if (this.compiledItems.length >= 2 && !this.action.prePopulate) {
      //if 2 or more lines are selected and they're not prepopulated you're for example changing the status of multiple grid records
      //compiledItems here will always be ICompiledLine because users cannot edit multple gant tasks at the same time
      let ids = this.compiledItems.map(compiledLine => compiledLine.id).join(';');      
      this.radController.updateMultipleRecords(this.nodeId, this.userId, this.action.name, ids, this.parameters).then(result => {
        this.handleSaveResult(result);
      });

      return;
    }

    this.radController.updateMultipleRecordsPrepopulated(this.nodeId, this.userId, this.action.name, this.parameters, this.compiledItems as ICompiledLine[]).then(result => {
      this.handleSaveResult(result);
    });
  }

  handleSaveResult(result: string): void {
    this.app.hideLoader();
    if (result == 'noresult' || result == '') {
      this.parameters = [];
      this.app.alertSuccess(this.app.translations.ALERT_Success_Success);
      this.communicationService.refreshAll();
      return;
    }

    this.communicationService.showFeedbackDialog(result);
  }

  removeImage(param: IParameter): void {
    this.updated = true;
    param.value = 'removed';
    param.preselectedValue = '';
    if (this.compiledItems.length >= 2) {
      this.app.alertInfo(this.app.translations.ALERT_Info_ImageRemoved);
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Esc') this.close();
  }

  confirm(): void {
    if (this.invalidValues) {
      this.app.alertError(this.app.translations.ALERT_Error_InvalidValues);
      return;
    }

    if (!this.updated) {
      this.app.alertInfo(this.app.translations.ALERT_Info_NothingToSave);
      return;
    }

    if (this.compiledItems.length > 1) {
      this.showConfirmation = true;
      return;
    }

    this.save();
  }

  close(): void {
    this.parameters = [];
    this.communicationService.closeDialog();
  }
}