<ng-template [ngIf]="!viewerMode">
  <dx-report-designer [reportUrl]="reportUrl" height="100vh">
    <dxrd-request-options [getDesignerModelAction]="getDesignerModelAction" [host]="hostUrl"></dxrd-request-options>
    <dxrd-designer-model-settings [allowMDI]="true">
      <dxrd-wizard-settings [useFullscreenWizard]="true" [enableSqlDataSource]="true"></dxrd-wizard-settings>
    </dxrd-designer-model-settings>
  </dx-report-designer>
</ng-template>
<ng-template [ngIf]="viewerMode">
  <ng-template [ngIf]="loadedFromDirect">
    <button class="btn absolute btn-back"
      (click)="back()"><i class="fa-solid fa-arrow-left-long"></i></button>
  </ng-template>
  <dx-report-viewer [reportUrl]="reportUrl" height="100vh">
    <dxrv-request-options [invokeAction]="invokeAction" [host]="hostUrl"></dxrv-request-options>
  </dx-report-viewer>
</ng-template>