<div class="animated fadeIn" [ngClass]="[!isMenuExpanded ? 'text-centre' : '', 
  isMenuExpanded && !item ? 'no-margin' : 
  (isMenuExpanded && item ? 'indent-margin' : (!item ? 'no-margin' : ''))]">
  <ng-template [ngIf]="items.length == 0">
    <div class="cursorPointer relative animated fadeIn menu-item" 
      [ngClass]="['padding' + menuItemPadding, darkMode ? 'bg-dark' : 'bg-white']">
      <i class="fa-solid fa-circle-notch font17 menu-item-arrow animated rotate" [ngClass]="darkMode ? 'textWhite' : 'textBlack'"></i>
    </div>
  </ng-template>
  <ng-template [ngIf]="items.length > 0">
    <ng-container *ngFor="let item of items">
      <div class="cursorPointer relative animated fadeIn menu-item" (click)="getView(item)"
        [ngClass]="['padding' + menuItemPadding, darkMode ? 'bg-dark' : 'bg-white']">
        <i class="fa-solid fa-chevron-down transition4 menu-item-arrow" [ngStyle]="{'transform': item.expanded ? 'rotate(0deg)' : 'rotate(-90deg)', 
            'visibility': !item.hasChildern ? 'hidden' : 'visible'}"
          [ngClass]="['font' + globalFontSize, darkMode ? 'textWhite' : 'textBlack']"></i>
        <span pTooltip="{{item.name}}" tooltipPosition="right" [tooltipDisabled]="isMenuExpanded">
          <ng-template [ngIf]="item.imageIcon != null">
            <img [src]="item.imageIcon" width="23" height="23" />
          </ng-template>
          <span class="animated fadeIn menu-item-text b-radius padding3"
            [ngClass]="['font' + globalFontSize, darkMode ? 'textWhite menu-item-dark' : 'textBlack menu-item-light']"
            [ngStyle]="{'background': item.selected && item.viewType == 'MasterDetailView' ? (darkMode ? 'var(--dark-second)' : 'var(--light-blue)') : '', 
                      'display': isMenuExpanded ? 'unset' : 'none'}">
            {{item.name}}
          </span>
        </span>
      </div>
      <ng-template [ngIf]="item.expanded && item.hasChildern">
        <app-menu [item]="item" [isMenuExpanded]="isMenuExpanded" [userId]="userId" [globalFontSize]="globalFontSize"
          [menuItemPadding]="menuItemPadding" [nodeId]="nodeId" [darkMode]="darkMode"></app-menu>
      </ng-template>
    </ng-container>
  </ng-template>
</div>