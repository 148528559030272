import { Injectable } from "@angular/core";
import { ICompiledLine } from "app/_models/ICompiledLine";
import { ILine } from "app/_models/ILine";
import { CommunicationService } from "app/_services/communication.service";

@Injectable({ providedIn: 'root' })
export class SelectExpandController {
  constructor(private communicationService: CommunicationService) { }
  
  reExpandRows(expandedRows: number[], lines: ILine[]): ILine[] {    
    for (var i = 0; i < lines.length; i++) {
      for (var j = 0; j < expandedRows.length; j++) {
        if (Number(lines[i].rowId) == expandedRows[j]) lines[i].expanded = true;
      }
    }

    return lines;
  }

  reSelectRows(compiledLines: ICompiledLine[], lines: ILine[]): ILine[] {
    for (var i = 0; i < lines.length; i++) {
      for (var j = 0; j < compiledLines.length; j++) {
        if (lines[i].rowId == compiledLines[j].id) lines[i].selected = true;
      }
    }

    return lines;
  }

  shiftSelect(compiledLines: ICompiledLine[], lines: ILine[]): { compiledLines: ICompiledLine[], lines: ILine[] } {    
    if (compiledLines.length == 2) {
      //if user is shift selected from bottom to top just swap the ids so everything still selects
      if (compiledLines[0].lineNumber > compiledLines[1].lineNumber) {
        let temp = compiledLines[1];
        compiledLines[1] = compiledLines[0];
        compiledLines[0] = temp;
      }

      lines.forEach(line => {
        if (line.lineNumber > compiledLines[0].lineNumber && line.lineNumber < compiledLines[1].lineNumber) {
          line.selected = true;          
          compiledLines.push({ id: line.rowId, lineData: line.list, lineNumber: line.lineNumber });          
        }
      })               
    }

    return { compiledLines, lines }; 
  }

  unselectRow(compiledLines: ICompiledLine[], rowData: ILine): { compiledLines: ICompiledLine[] } { //just see if it can return just :ICompiledLine[] instead of an object
    let index = compiledLines.findIndex(val => val.id === rowData.rowId);
    if (index !== -1) compiledLines.splice(index, 1);
    return { compiledLines };
  }

  compileLines(selectedLine: ILine, shiftSelect: boolean, controlSelect: boolean, compiledLines: ICompiledLine[], lines: ILine[], previousViewName: string, currentViewName: string, detailGrid: boolean): {compiledLines: ICompiledLine[], lines: ILine[], currentViewName: string} {  
    if (shiftSelect) {
      if (selectedLine.selected) {
        if (previousViewName != currentViewName && detailGrid) compiledLines = [];//if child table is active, reset

        compiledLines.push({ id: selectedLine.rowId, lineData: selectedLine.list, lineNumber: selectedLine.lineNumber });
        let result = this.shiftSelect(compiledLines, lines);
        compiledLines = result.compiledLines;
        lines = result.lines;

        if (compiledLines.length == 2 && detailGrid) {
          this.communicationService.deselectParentLines();
          previousViewName = currentViewName;//updates viewname for subsequent checks   
        }
      }
    } else if (controlSelect) {
      if (selectedLine.selected) {
        compiledLines.push({ id: selectedLine.rowId, lineData: selectedLine.list, lineNumber: selectedLine.lineNumber });
        compiledLines.forEach(compiledLine => {
          lines.forEach(line => {
            if (line.rowId == compiledLine.id) {
              line.selected = true;
            }
          })
        })

        if(detailGrid) {
          this.communicationService.deselectParentLines();
          previousViewName = currentViewName;//updates viewname for subsequent checks    
        }         
      } else {
        let result = this.unselectRow(compiledLines, selectedLine);
        compiledLines = result.compiledLines;
      }
    } else if (selectedLine.selected) {
      compiledLines = [];//if child table is active, reset              
      compiledLines.push({ id: selectedLine.rowId, lineData: selectedLine.list, lineNumber: selectedLine.lineNumber });
      lines.forEach(line => { line.rowId == selectedLine.rowId ? line.selected = true : line.selected = false });
      if(detailGrid) {
        this.communicationService.deselectParentLines();
        previousViewName = currentViewName;//updates viewname for subsequent checks  
      }          
    } else if (!selectedLine.selected) {
      let result = this.unselectRow(compiledLines, selectedLine);
      compiledLines = result.compiledLines;
    }
    
    return {compiledLines, lines, currentViewName};
  }
}