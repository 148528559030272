<div class="row main transition5" [class.disable-selection]="shiftSelect || controlSelect"
  [ngClass]="{'change-scrollbar-color-darkMode': darkMode, 'change-scrollbar-color': !darkMode}">
  <div class="animated fadeIn view-container unset-p" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="relative padding5 full-width transition5 auto-height"
      [ngClass]="{'bg-dark': darkMode, 'bg-white': !darkMode}">
      <ng-template [ngIf]="headers.length > 0">
        <ng-template [ngIf]="!mobileView">
          <div class="row" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'" style="margin-bottom: 5px !important;">
            <div class="padding5 b-radius control-bar" 
              [ngClass]="[darkMode ? 'bg-dark-secondary' : 'bg-light-blue', zoomedDetailView ? 'display-none' : '']">
              <div class="col-4 flex unset-p">
                <button class="btn font17" (click)="toggleCompactGrid()">
                  <i
                    class="fa-solid fa-down-left-and-up-right-to-center padding3 b-radius textGreen control-bar-btn-icon"></i>
                </button>                
                <button class="btn font17" (click)="toggleFilters()">
                  <i class="fa-solid fa-filter textGreen padding3 b-radius control-bar-btn-icon"></i>
                  <span class="control-bar-btn-text"
                    [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_btn_FilterText}}</span>
                </button>
                <button class="btn font17" (click)="openColumnDialog()">
                  <i class="fa-solid fa-table-columns textGreen padding3 b-radius control-bar-btn-icon"></i>
                  <ng-template [ngIf]="!mobileView">
                    <span class="control-bar-btn-text"
                      [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_btn_ColumnsText}}</span>
                  </ng-template>
                </button>
                <span [ngClass]="darkMode ? 'textWhite' : 'textBlack'"
                  class="font14 animated fadeIn padding10 justify-centre align-centre b-radius record-count">
                  {{rowCountFrom}} - {{rowCountTo}} {{app.translations.GBL_lbl_Of}} {{totalRecords}} {{app.translations.GBL_lbl_Rows}}
                </span>
              </div>
              <div class="offset-5 col-3 flex unset-p flex justify-right align-centre page-controls-container">
                <select class="font14 unset-b padding5 b-radius unset-outline cursorPointer"
                  [ngClass]="darkMode ? 'bg-dark-secondary textWhite' : 'bg-white textBlack'"
                  (change)="changePageSize($event)">
                  <option class="textBlack">10</option>
                  <option class="textBlack" selected>20</option>
                  <option class="textBlack">50</option>
                  <option class="textBlack">100</option>
                </select>
                <div class="p-mb-3 flex justify-right align-centre paginator">
                  <button (click)="first()" class="btn font17 textGreen" [disabled]="isFirstPage()"
                    pTooltip="{{app.translations.GBL_hint_PaginateFirst}}" tooltipPosition="top"
                    [ngClass]="{'cursorPointer': !isFirstPage(), 'cursorNotAllowed': isFirstPage()}">
                    <i class="fa-solid fa-angles-left"></i>
                  </button>
                  <button (click)="prev()" class="btn font17 textGreen"
                    [ngClass]="{'cursorPointer': !isFirstPage(), 'cursorNotAllowed': isFirstPage()}"
                    pTooltip="{{app.translations.GBL_hint_PaginatePrevious}}" tooltipPosition="top"
                    [disabled]="isFirstPage()">
                    <i class="fa-solid fa-chevron-left"></i>
                  </button>
                  <span [ngClass]="darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-white'"
                    class="font15 page-number transition5 b-radius">
                    {{currentpage}}
                  </span>
                  <button (click)="next()" class="btn font17 textGreen"
                    [ngClass]="{'cursorPointer': !isLastPage(), 'cursorNotAllowed': isLastPage()}"
                    pTooltip="{{app.translations.GBL_hint_PaginateNext}}" tooltipPosition="top"
                    [disabled]="isLastPage()">
                    <i class="fa-solid fa-chevron-right"></i>
                  </button>
                  <button (click)="last()" class="btn font17 textGreen" [disabled]="isLastPage()"
                    pTooltip="{{app.translations.GBL_hint_PaginateLast}}" tooltipPosition="top"
                    [ngClass]="{'cursorPointer': !isLastPage(), 'cursorNotAllowed': isLastPage()}">
                    <i class="fa-solid fa-angles-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div #tabelDiv class="animated fadeIn overflow-scroll grid-data-container">
            <p-table #dt (mouseover)="getColWidth()" [value]="lines" [columns]="headers" [reorderableColumns]="true"
              styleClass="p-datatable-gridlines" [resizableColumns]="true" responsiveLayout="scroll">
              <ng-template [ngIf]="headers.length > 0">
                <ng-template pTemplate="header" let-columns>
                  <!--#region Headers  -->
                  <tr (mouseover)="getViewName()" [ngClass]="zoomedDetailView ? 'display-none' : ''">
                    <ng-template [ngIf]="hasChildren">
                      <th class="arrow-column" pResizableColumn [ngClass]="[darkMode ? 'bg-dark' : 'bg-white']"></th>
                    </ng-template>
                    <ng-container *ngFor="let header of headers">
                      <ng-container *ngFor="let filter of filters">
                        <ng-template [ngIf]="header.visible && header.caption == filter.caption">
                          <th #tablerow (drag)="getColOrder(columns)" pResizableColumn pReorderableColumn
                            class="fit-content-max-width"
                            [ngStyle]="{'min-width': '50px', 'max-width': header.width, 'width': header.width }"
                            (dblclick)="removeSort(header.field)"
                            [ngClass]="['padding' + tablePadding, 'font' + globalFontSize, darkMode ? 'bg-dark': 'bg-white']">
                            <span class="word-break" (click)="sort(header)"
                              (keydown.control)="handleControlKeyPress($event)" [ngClass]="{'sorted-column': colSortedBy === header.field, 
                              'active-filter': filter.isActive && !darkMode, 
                              'active-filter-dark': filter.isActive && darkMode,
                              'textGreyb9': filter.isNull, 
                              'textBlack': !filter.isNull && !darkMode, 
                              'textWhite': !filter.isNull && darkMode,
                              'cursorPointer': header.displayFormat != 'IMAGE',
                              'cursorNotAllowed': header.displayFormat == 'IMAGE'}">
                              <ng-template [ngIf]="sortString && colSortedBy == header.field">
                                <i class="fa-solid"
                                  [ngClass]="[sortOrder ? 'fa-arrow-up-long' : 'fa-arrow-down-long', 'font' + globalFontSize, 'padding' + tablePadding]"></i>
                              </ng-template>
                              {{header.caption}}
                            </span>
                          </th>
                        </ng-template>
                      </ng-container>
                    </ng-container>
                  </tr>
                  <ng-template [ngIf]="showFilters">
                    <tr class="animated fadeIn" [ngClass]="zoomedDetailView ? 'display-none' : ''">
                      <ng-template [ngIf]="hasChildren">
                        <td class="arrow-column" pResizableColumn [ngClass]="[darkMode ? 'bg-dark' : 'bg-white']"></td>
                      </ng-template>
                      <ng-container *ngFor="let header of headers">
                        <ng-container *ngFor="let filter of filters">
                          <ng-template [ngIf]="header.visible && header.caption == filter.caption">
                            <ng-template [ngIf]="header.displayFormat == 'TIME'">
                              <td (dblclick)="clearFilter(filter)" class="relative cursorPointer"
                                [ngClass]="['padding' + tablePadding, darkMode ? 'bg-dark' : 'bg-white']">
                                <button class="absolute bg-none unset-p unset-b range-filter-btn"
                                  [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" (click)="op.toggle($event)">
                                  <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                                </button>
                                <p-overlayPanel #op>
                                  <ng-template pTemplate="content">
                                    <div class="b-radius box-shadow" style="padding: 20px 0px 10px;"
                                      [ngClass]="[darkMode ? 'bg-dark' : 'bg-white']">
                                      <div class="row">
                                        <div class="col-12 unset-p">
                                          <span class="font15 padding5 b-radius"
                                            [ngClass]="[darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-light-blue']">
                                            {{app.translations.GBL_lbl_ChooseRange}}
                                          </span>
                                          <ng-template [ngIf]="filter.isNull">
                                            <span class="font14 textGreyb9 float-right">null</span>
                                          </ng-template>
                                        </div>
                                      </div><br />
                                      <div class="row">
                                        <div class="col-6">
                                          <span class="font14" [ngClass]="[darkMode ? 'textWhite' : 'textBlack']">
                                            {{app.translations.GBL_lbl_RangeFrom}}
                                          </span><br />
                                          <input class="form-control textBlack font14 input-filter" step=1 type="time"
                                            #txtFrom [value]="filter.range.length > 0 ? filter.range[0] : ''"
                                            (contextmenu)="nullifyDateFilter(filter, header, $event)" />
                                        </div>
                                        <div class="col-6">
                                          <span class="font14" [ngClass]="[darkMode ? 'textWhite' : 'textBlack']">
                                            {{app.translations.GBL_lbl_RangeTo}}
                                          </span><br />
                                          <input class="form-control textBlack font14 input-filter" step=1 type="time"
                                            #txtTo [value]="filter.range.length > 0 ? filter.range[1] : ''"
                                            (contextmenu)="nullifyDateFilter(filter, header, $event)" />
                                        </div>
                                      </div><br />
                                      <div class="row">
                                        <div class="col-12 flex justify-right">
                                          <button class="btn font17" (click)="createRangeFilter(filter)">
                                            <i
                                              class="fa-solid fa-check textGreen padding3 b-radius control-bar-btn-icon"></i>
                                            <span class="control-bar-btn-text"
                                              [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_btn_ApplyText}}</span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </ng-template>
                                </p-overlayPanel>
                              </td>
                            </ng-template>
                            <ng-template [ngIf]="header.displayFormat == 'DATE'">
                              <td (dblclick)="clearFilter(filter)" class="relative cursorPointer"
                                [ngClass]="['padding' + tablePadding, darkMode ? 'bg-dark' : 'bg-white']">
                                <button class="absolute bg-none unset-p unset-b range-filter-btn" tooltipPosition="top"
                                  [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" (click)="op.toggle($event)"
                                  pTooltip="{{getDateFilterTooltip(filter)}}"
                                  [tooltipDisabled]="filter.range.length == 0">
                                  <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                                </button>
                                <p-overlayPanel #op>
                                  <ng-template pTemplate="content">
                                    <div class="b-radius box-shadow" style="padding: 20px 0px 10px;"
                                      [ngClass]="[darkMode ? 'bg-dark' : 'bg-white']">
                                      <div class="row">
                                        <div class="col-12">
                                          <span class="font15 padding5 b-radius"
                                            [ngClass]="[darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-light-blue']">
                                            {{app.translations.GBL_lbl_ChooseRange}}
                                          </span>
                                          <ng-template [ngIf]="filter.isNull">
                                            <span class="font14 italic textGreyb9"
                                              style="padding-left: 5px;">(null)</span>
                                          </ng-template>
                                        </div>
                                      </div><br />
                                      <div class="row">
                                        <div class="col-6">
                                          <span class="p-float-label">
                                            <p-calendar [(ngModel)]="filter.range[0]" [showTime]="false"
                                              [showSeconds]="true" [showButtonBar]="true" [showIcon]="true"
                                              [dateFormat]="checkDateFormat()" class="font14" inputId="from_date"
                                              (contextmenu)="nullifyDateFilter(filter, header, $event)"></p-calendar>
                                            <label for="from_date"
                                              class="textGreyb9 font13">{{app.translations.GBL_lbl_RangeFrom}}</label>
                                          </span>
                                        </div>
                                        <div class="col-6">
                                          <span class="p-float-label">
                                            <p-calendar [(ngModel)]="filter.range[1]" [showTime]="false"
                                              [showSeconds]="true" [showButtonBar]="true" [showIcon]="true"
                                              [dateFormat]="checkDateFormat()" class="font14" inputId="to_date"
                                              (contextmenu)="nullifyDateFilter(filter, header, $event)"></p-calendar>
                                            <label for="to_date"
                                              class="textGreyb9 font13">{{app.translations.GBL_lbl_RangeTo}}</label>
                                          </span>
                                        </div>
                                      </div><br />
                                      <div class="row">
                                        <div class="col-12 flex justify-right">
                                          <button class="btn font17" (click)="createRangeFilter(filter)">
                                            <i
                                              class="fa-solid fa-check textGreen padding3 b-radius control-bar-btn-icon"></i>
                                            <span class="control-bar-btn-text"
                                              [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_btn_ApplyText}}</span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </ng-template>
                                </p-overlayPanel>
                              </td>
                            </ng-template>
                            <ng-template [ngIf]="header.displayFormat == 'DATETIME'">
                              <td (dblclick)="clearFilter(filter)" class="relative cursorPointer"
                                [ngClass]="['padding' + tablePadding, darkMode ? 'bg-dark' : 'bg-white']">
                                <button class="absolute bg-none unset-p unset-b range-filter-btn" tooltipPosition="top"
                                  [ngClass]="darkMode ? 'textWhite' : 'textBlack'" (click)="op.toggle($event)"
                                  pTooltip="{{getDateTimeFilterTooltip(filter)}}"
                                  [tooltipDisabled]="filter.range.length == 0">
                                  <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                                </button>
                                <p-overlayPanel #op>
                                  <ng-template pTemplate="content">
                                    <div class="b-radius box-shadow" style="padding: 20px 0px 10px;"
                                      [ngClass]="[darkMode ? 'bg-dark' : 'bg-white']">
                                      <div class="row">
                                        <div class="col-12">
                                          <span class="font15 padding5 b-radius"
                                            [ngClass]="[darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-light-blue']">
                                            {{app.translations.GBL_lbl_ChooseRange}}
                                          </span>
                                          <ng-template [ngIf]="filter.isNull">
                                            <span class="font13 textGreyb9 float-right">null</span>
                                          </ng-template>
                                        </div>
                                      </div><br />
                                      <div class="row">
                                        <div class="col-6">
                                          <span class="p-float-label">
                                            <p-calendar [(ngModel)]="filter.range[0]" [showTime]="true"
                                              [showSeconds]="true" [showButtonBar]="true" [showIcon]="true"
                                              [dateFormat]="checkDateFormat()" class="font14" inputId="from_date"
                                              (contextmenu)="nullifyDateFilter(filter, header, $event)"></p-calendar>
                                            <label for="from_date"
                                              class="textGreyb9 font13">{{app.translations.GBL_lbl_RangeFrom}}</label>
                                          </span>
                                        </div>
                                        <div class="col-6">
                                          <span class="p-float-label">
                                            <p-calendar [(ngModel)]="filter.range[1]" [showTime]="true"
                                              [showSeconds]="true" [showButtonBar]="true" [showIcon]="true"
                                              [dateFormat]="checkDateFormat()" class="font14" inputId="to_date"
                                              (contextmenu)="nullifyDateFilter(filter, header, $event)"></p-calendar>
                                            <label for="to_date"
                                              class="textGreyb9 font13">{{app.translations.GBL_lbl_RangeTo}}</label>
                                          </span>
                                        </div>
                                      </div><br />
                                      <div class="row">
                                        <div class="col-12 flex justify-right">
                                          <button class="btn font17" (click)="createRangeFilter(filter)">
                                            <i
                                              class="fa-solid fa-check textGreen padding3 b-radius control-bar-btn-icon"></i>
                                            <span class="control-bar-btn-text"
                                              [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_btn_ApplyText}}</span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </ng-template>
                                </p-overlayPanel>
                              </td>
                            </ng-template>
                            <ng-template [ngIf]="header.displayFormat == 'CHECK'">
                              <td (dblclick)="clearFilter(filter)"
                                [ngClass]="['padding' + tablePadding, darkMode ? 'bg-dark' : 'bg-white']">
                                <mat-checkbox #chk
                                  [checked]="preSelectCheckBoxFilter(header, filter.field, filter.value)"
                                  [indeterminate]="filter.isNull"
                                  (change)="updateValue(chk.value, header.field, $event, filter)"
                                  (contextmenu)="nullifyFilter(filter, header, $event)">
                                </mat-checkbox>
                              </td>
                            </ng-template>
                            <ng-template [ngIf]="header.displayFormat == '' || header.displayFormat == 'string' 
                              || header.displayFormat == 'IMAGE' || header.displayFormat == 'HTML'">
                              <td (dblclick)="clearFilter(filter)"
                                [ngClass]="['padding' + tablePadding, darkMode ? 'bg-dark' : 'bg-white']">
                                <input class="form-control b-radius input-filter" type="text" #txtFilter
                                  [ngClass]="['font' + globalFontSize, 'padding' + tablePadding, darkMode ? 'textWhite' : 'textBlack']"
                                  placeholder="..." (keyup)="updateValue(txtFilter.value, header.field, $event, filter)"
                                  [disabled]="header.displayFormat == 'IMAGE' || filter.dataType == 'Byte[]'"
                                  [value]="header.field == filter.field ? filter.value : ''"
                                  (contextmenu)="nullifyFilter(filter, header, $event)" />
                              </td>
                            </ng-template>
                          </ng-template>
                        </ng-container>
                      </ng-container>
                    </tr>
                  </ng-template>
                  <!--#endregion -->
                </ng-template>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <!--#region Rows -->
                <tr (mouseover)="getViewName()" [ngClass]="zoomedDetailView ? 'display-none' : ''">
                  <ng-template [ngIf]="rowData.children.length > 0">
                    <td class="transition4 font14 unset-white-space"
                      [ngClass]="['padding' + tablePadding,
                      darkMode ? (rowData.selected ? 'bg-green' : 'bg-dark') : (rowData.selected ? 'bg-green' : 'bg-white')]">
                      <button type="button" (click)="expand(rowData);"
                        [ngStyle]="{'display': rowData.children.length > 0 ? 'contents' : 'none' }">
                        <i class="fa-solid fa-chevron-down transition4"
                          [ngStyle]="{'transform': rowData.expanded ? 'rotate(0deg)' : 'rotate(-90deg)'}"
                          [ngClass]="[darkMode ? (rowData.selected ? 'textWhite' : 'textWhite') : (!rowData.selected ? 'textBlack' : 'textWhite')]"></i>
                      </button>
                    </td>
                  </ng-template>
                  <ng-container *ngFor="let col of columns">
                    <ng-template [ngIf]="col.visible">
                      <td class="cursorPointer transition4" (click)="compileLines(rowData)" [ngClass]="['padding' + tablePadding, showCompactGrid ? 'compact-grid' : '',
                        darkMode ? (rowData.selected ? 'textWhite bg-green' : 'textWhite bg-dark') : 
                        (!rowData.selected ? 'textBlack bg-white' : 'textWhite bg-green')]"
                        [tooltipDisabled]="!showCompactGrid || col.displayFormat == 'IMAGE' || col.displayFormat == 'HTML'"
                        pTooltip="{{rowData.list[col.caption]}}" tooltipPosition="top">
                        <ng-template [ngIf]="col.displayFormat == 'CHECK'">
                          <mat-checkbox [(ngModel)]="rowData.list[col.caption]" [disabled]="true"
                            [indeterminate]="checkIndeterminate(rowData.list[col.caption])">
                          </mat-checkbox>
                        </ng-template>
                        <ng-template [ngIf]="col.displayFormat == 'IMAGE' && rowData.list[col.caption]">
                          <img [src]="rowData.list[col.caption]" class="padding2 b-radius" width="30" height="30"
                            [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-white'"
                            (dblclick)="expandImage(rowData.list[col.caption])" />
                        </ng-template>
                        <ng-template [ngIf]="col.displayFormat == 'HTML' && !showCompactGrid">
                          <div [ngClass]="'font' + globalFontSize"
                            [ngStyle]="{'white-space': !showCompactGrid ? 'normal' : 'nowrap'}"
                            [innerHTML]="rowData.list[col.caption]"></div>
                        </ng-template>
                        <ng-template [ngIf]="col.displayFormat == 'HTML' && showCompactGrid">
                          <div [ngClass]="'font' + globalFontSize"
                            pTooltip="{{'<span>' + rowData.list[col.caption].replace('<br><br>', '') + '</span>'}}"
                            tooltipPosition="bottom" [escape]="false" [tooltipDisabled]="!showCompactGrid"
                            [autoHide]="false" [ngStyle]="{'white-space': !showCompactGrid ? 'normal' : 'nowrap'}">
                            <span>{{rowData.list[col.caption]}}</span>
                          </div>
                        </ng-template>
                        <ng-template [ngIf]="col.displayFormat == 'DATE'">
                          <span [ngClass]="'font' + globalFontSize"
                            [ngStyle]="{'white-space': !showCompactGrid ? 'normal' : 'nowrap'}">
                            {{rowData.list[col.caption]}}
                          </span>
                        </ng-template>
                        <ng-template [ngIf]="col.displayFormat == 'TIME'">
                          <span [ngClass]="'font' + globalFontSize"
                            [ngStyle]="{'white-space': !showCompactGrid ? 'normal' : 'nowrap'}">
                            {{rowData.list[col.caption]}}
                          </span>
                        </ng-template>
                        <ng-template [ngIf]="col.displayFormat == 'DATETIME'">
                          <span [ngClass]="'font' + globalFontSize"
                            [ngStyle]="{'white-space': !showCompactGrid ? 'normal' : 'nowrap'}">
                            {{rowData.list[col.caption]}}
                          </span>
                        </ng-template>
                        <ng-template [ngIf]="col.displayFormat == ''">
                          <span [ngClass]="'font' + globalFontSize"
                            [ngStyle]="{'white-space': !showCompactGrid ? 'normal' : 'nowrap'}">
                            {{rowData.list[col.caption].trim()}}
                          </span>
                        </ng-template>
                      </td>
                    </ng-template>
                  </ng-container>
                </tr>
                <ng-template [ngIf]="rowData.expanded && rowData.children.length > 0">
                  <tr class="animated fadeIn">
                    <td class="unset-p" [attr.colspan]="headers.length+1" [ngClass]="[darkMode ? 'bg-dark' : 'bg-white', zoomedDetailView ? 'unset-b' : '']">
                      <app-detail-grid [children]="rowData.children" [rowId]="rowData.rowId" [darkMode]="darkMode"
                        [globalFontSize]="globalFontSize" [tablePadding]="tablePadding" [nodeId]="nodeId"
                        [userId]="userId" [username]="username" [showCompactGrid]="showCompactGrid"
                        [dateFormat]="dateFormat" [detailedErrorsEnabled]="detailedErrorsEnabled"></app-detail-grid>
                    </td>
                  </tr>
                </ng-template>
                <!--#endregion -->
              </ng-template>
            </p-table>
            <ng-template [ngIf]="showNoRecordsFoundLabel">
              <span class="font15 padding10 text-centre b-radius inline-block full-width no-records"
                [ngClass]="darkMode ? 'bg-dark-secondary textWhite' : 'bg-light-blue textBlack'">
                {{app.translations.GBL_lbl_NoRecords}}
              </span>
            </ng-template>
          </div>
        </ng-template>
        <ng-template [ngIf]="mobileView">
          <div class="relative full-vh-height">
            <div class="mobile-view-container">
              <div class="row full-width" style="margin-bottom: 15px !important;"
                [ngClass]="darkMode ? 'bg-dark' : 'bg-white'" >
                <div class="padding5 b-radius control-bar" [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
                  <div class="col-4 flex unset-p">
                    <button class="btn font17" (click)="op.toggle($event)">
                      <i class="fa-solid fa-arrow-up-wide-short padding3 b-radius textGreen control-bar-btn-icon"></i>
                    </button>
                    <p-overlayPanel #op>
                      <ng-template pTemplate="content">
                        <div class="b-radius box-shadow padding7" style="width: max-content;"
                          [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
                          <div class="row">
                            <div class="col-12 unset-p">
                              <label [ngClass]="darkMode ? 'textWhite' : 'textBlack'" class="font14">
                                <input type="radio" name="sortOrder" [(ngModel)]="sortOrder" [value]="true">
                                {{app.translations.GBL_lbl_Ascending}}
                              </label><br>
                              <label [ngClass]="darkMode ? 'textWhite' : 'textBlack'" class="font14">
                                <input type="radio" name="sortOrder" [(ngModel)]="sortOrder" [value]="false">
                                {{app.translations.GBL_lbl_Descending}}
                              </label>
                            </div>
                          </div><br>
                          <div class="row">
                            <div class="col-12 unset-p">
                              <ng-container *ngFor="let header of headers">
                                <ng-template [ngIf]="header.visible">
                                  <mat-checkbox [checked]="colSortedBy == header.field"
                                    (change)="updateSortByColumnMobile(header)"
                                    [disabled]="header.displayFormat == 'IMAGE' || header.displayFormat == 'ICON'">
                                    <span [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
                                      {{header.caption}}
                                    </span>
                                  </mat-checkbox>
                                  <br><br>
                                </ng-template>
                              </ng-container>
                            </div>
                            <div class="col-12 unset-p justify-right flex">
                              <button class="btn font16" (click)="sortFromMobile()">
                                <i class="fa-solid fa-check textGreen control-bar-btn-icon"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </ng-template>
                    </p-overlayPanel>
                    <button class="btn font17 relative" (click)="opFilters.toggle($event)">
                      <i class="fa-solid fa-filter textGreen padding3 b-radius control-bar-btn-icon"></i>
                      <ng-template [ngIf]="activeFilterCount > 0">
                        <span class="absolute font10"
                          [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{activeFilterCount}}</span>
                      </ng-template>
                    </button>
                    <p-overlayPanel #opFilters>
                      <ng-template pTemplate="content">
                        <div class="b-radius box-shadow padding7 filter-overlay"
                          [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
                          <ng-container *ngFor="let header of headers">
                            <ng-container *ngFor="let filter of filters">
                              <ng-template [ngIf]="header.visible && header.caption == filter.caption">
                                <div class="row" style="margin-bottom: 20px !important;">
                                  <div class="col-10 unset-p">
                                    <span
                                      [ngClass]="{'textWhite': darkMode, 'textBlack': !darkMode,
                                      'active-filter': filter.isActive && !darkMode, 'active-filter-dark': filter.isActive && darkMode}"
                                      class="font14">{{header.caption}}</span><br>
                                    <ng-template [ngIf]="header.displayFormat == 'TIME'">
                                      <button class="bg-none unset-p unset-b"
                                        [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" (click)="op.toggle($event)">
                                        <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                                      </button>
                                      <p-overlayPanel #op>
                                        <ng-template pTemplate="content">
                                          <div class="b-radius box-shadow overlay-panel" [ngClass]="[darkMode ? 'bg-dark' : 'bg-white']">
                                            <div class="row">
                                              <div class="col-12">
                                                <span class="font15 padding5 b-radius"
                                                  [ngClass]="[darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-light-blue']">
                                                  {{app.translations.GBL_lbl_ChooseRange}}
                                                </span>                                                
                                              </div>
                                            </div><br />
                                            <div class="row">
                                              <div class="col-12">
                                                <span class="font14" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
                                                  {{app.translations.GBL_lbl_RangeFrom}}
                                                </span><br />
                                                <input class="form-control textBlack font14 input-filter" step=1
                                                  type="time" #txtFrom
                                                  [value]="filter.range.length > 0 ? filter.range[0] : ''"/>
                                              </div>
                                              <div class="col-12">
                                                <span class="font14" [ngClass]="[darkMode ? 'textWhite' : 'textBlack']">
                                                  {{app.translations.GBL_lbl_RangeTo}}
                                                </span><br />
                                                <input class="form-control textBlack font14 input-filter" step=1
                                                  type="time" #txtTo
                                                  [value]="filter.range.length > 0 ? filter.range[1] : ''" />
                                              </div>
                                            </div><br />
                                            <div class="row">
                                              <div class="col-12 flex justify-right">
                                                <button class="btn font17" (click)="createRangeFilter(filter)">
                                                  <i class="fa-solid fa-check textGreen padding3 b-radius control-bar-btn-icon"></i>                                                  
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </ng-template>
                                      </p-overlayPanel>
                                    </ng-template>
                                    <ng-template [ngIf]="header.displayFormat == 'DATE'">
                                      <button class="bg-none unset-p unset-b" tooltipPosition="top"
                                        [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" (click)="op.toggle($event)"
                                        pTooltip="{{getDateFilterTooltip(filter)}}"
                                        [tooltipDisabled]="filter.range.length == 0">
                                        <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                                      </button>
                                      <p-overlayPanel #op>
                                        <ng-template pTemplate="content">
                                          <div class="b-radius box-shadow overlay-panel" [ngClass]="[darkMode ? 'bg-dark' : 'bg-white']">
                                            <div class="row">
                                              <div class="col-12">
                                                <span class="font15 padding5 b-radius"
                                                  [ngClass]="[darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-light-blue']">
                                                  {{app.translations.GBL_lbl_ChooseRange}}
                                                </span>                                               
                                              </div>
                                            </div><br />
                                            <div class="row">
                                              <div class="col-12">
                                                <span class="p-float-label">
                                                  <p-calendar [(ngModel)]="filter.range[0]" [showTime]="false"
                                                    [showSeconds]="true" [showButtonBar]="true" [showIcon]="true"
                                                    [dateFormat]="checkDateFormat()" class="font14" inputId="from_date"></p-calendar>
                                                  <label for="from_date"
                                                    class="textGreyb9 font13">{{app.translations.GBL_lbl_RangeFrom}}</label>
                                                </span>
                                              </div>
                                              <div class="col-12">
                                                <span class="p-float-label">
                                                  <p-calendar [(ngModel)]="filter.range[1]" [showTime]="false"
                                                    [showSeconds]="true" [showButtonBar]="true" [showIcon]="true"
                                                    [dateFormat]="checkDateFormat()" class="font14" inputId="to_date"></p-calendar>
                                                  <label for="to_date"
                                                    class="textGreyb9 font13">{{app.translations.GBL_lbl_RangeTo}}</label>
                                                </span>
                                              </div>
                                            </div><br />
                                            <div class="row">
                                              <div class="col-12 flex justify-right">
                                                <button class="btn font17" (click)="createRangeFilter(filter)">
                                                  <i class="fa-solid fa-check textGreen padding3 b-radius control-bar-btn-icon"></i>                                                  
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </ng-template>
                                      </p-overlayPanel>
                                    </ng-template>
                                    <ng-template [ngIf]="header.displayFormat == 'DATETIME'">
                                      <button class="bg-none unset-p unset-b" tooltipPosition="top"
                                        [ngClass]="darkMode ? 'textWhite' : 'textBlack'" (click)="op.toggle($event)"
                                        pTooltip="{{getDateTimeFilterTooltip(filter)}}"
                                        [tooltipDisabled]="filter.range.length == 0">
                                        <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
                                      </button>
                                      <p-overlayPanel #op>
                                        <ng-template pTemplate="content">
                                          <div class="b-radius box-shadow overlay-panel" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
                                            <div class="row">
                                              <div class="col-12">
                                                <span class="font15 padding5 b-radius"
                                                  [ngClass]="darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-light-blue'">
                                                  {{app.translations.GBL_lbl_ChooseRange}}
                                                </span>
                                              </div>
                                            </div><br />
                                            <div class="row">
                                              <div class="col-12">
                                                <span class="p-float-label">
                                                  <p-calendar [(ngModel)]="filter.range[0]" [showTime]="true"
                                                    [showSeconds]="true" [showButtonBar]="true" [showIcon]="true"
                                                    [dateFormat]="checkDateFormat()" class="font14" inputId="from_date"></p-calendar>
                                                  <label for="from_date"
                                                    class="textGreyb9 font13">{{app.translations.GBL_lbl_RangeFrom}}</label>
                                                </span>
                                              </div>
                                              <div class="col-12">
                                                <span class="p-float-label">
                                                  <p-calendar [(ngModel)]="filter.range[1]" [showTime]="true"
                                                    [showSeconds]="true" [showButtonBar]="true" [showIcon]="true"
                                                    [dateFormat]="checkDateFormat()" class="font14" inputId="to_date"></p-calendar>
                                                  <label for="to_date"
                                                    class="textGreyb9 font13">{{app.translations.GBL_lbl_RangeTo}}</label>
                                                </span>
                                              </div>
                                            </div><br />
                                            <div class="row">
                                              <div class="col-12 flex justify-right">
                                                <button class="btn font17" (click)="createRangeFilter(filter)">
                                                  <i class="fa-solid fa-check textGreen padding3 b-radius control-bar-btn-icon"></i>                                                  
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </ng-template>
                                      </p-overlayPanel>
                                    </ng-template>
                                    <ng-template [ngIf]="header.displayFormat == 'CHECK'">
                                      <mat-checkbox #chk
                                        [checked]="preSelectCheckBoxFilter(header, filter.field, filter.value)"
                                        [indeterminate]="filter.isNull"
                                        (change)="updateValue(chk.value, header.field, $event, filter)">
                                      </mat-checkbox>
                                    </ng-template>
                                    <ng-template [ngIf]="header.displayFormat == '' || header.displayFormat == 'string' 
                                        || header.displayFormat == 'IMAGE' || header.displayFormat == 'HTML'">
                                      <input class="form-control b-radius input-filter" type="text" #txtFilter
                                        [ngClass]="['font' + globalFontSize, 'padding' + tablePadding, darkMode ? 'textWhite' : 'textBlack']"
                                        placeholder="..." style="margin-top: 5px !important;"
                                        (keyup)="updateValue(txtFilter.value, header.field, $event, filter)"
                                        [disabled]="header.displayFormat == 'IMAGE' || filter.dataType == 'Byte[]' || header.displayFormat == 'ICON'"
                                        [value]="header.field == filter.field ? filter.value : ''" />
                                    </ng-template>
                                  </div>
                                  <div class="col-2">
                                    <i class="fa-solid fa-xmark font16 control-bar-btn-icon"
                                      [ngClass]="darkMode ? 'textWhite' : 'textBlack'"
                                      (click)="clearFilter(filter)"></i>
                                  </div>
                                </div>
                              </ng-template>
                            </ng-container>
                          </ng-container>
                          <div class="col-12 unset-p justify-right flex">
                            <button class="btn font16" (click)="buildFilterStringFromMobile()">
                              <i class="fa-solid fa-check textGreen control-bar-btn-icon"></i>
                            </button>
                          </div>
                        </div>
                      </ng-template>
                    </p-overlayPanel>
                    <button class="btn font17" (click)="openColumnDialog()">
                      <i class="fa-solid fa-table-columns textGreen padding3 b-radius control-bar-btn-icon"></i>
                      <ng-template [ngIf]="!mobileView">
                        <span class="control-bar-btn-text"
                          [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_btn_ColumnsText}}</span>
                      </ng-template>
                    </button>
                    <span [ngClass]="darkMode ? 'textWhite' : 'textBlack'"
                      class="font14 animated fadeIn padding10 justify-centre align-centre b-radius record-count">
                      {{rowCountFrom}} - {{rowCountTo}} {{app.translations.GBL_lbl_Of}} {{totalRecords}}
                      {{app.translations.GBL_lbl_Rows}}
                    </span>
                  </div>
                  <div class="offset-5 col-3 flex unset-p flex justify-right align-centre page-controls-container">
                    <select class="font14 unset-b padding5 b-radius unset-outline cursorPointer"
                      [ngClass]="darkMode ? 'bg-dark-secondary textWhite' : 'bg-white textBlack'"
                      (change)="changePageSize($event)">
                      <option class="textBlack">10</option>
                      <option class="textBlack" selected>20</option>
                      <option class="textBlack">50</option>
                      <option class="textBlack">100</option>
                    </select>
                    <div class="p-mb-3 flex justify-right align-centre paginator">
                      <button (click)="first()" class="btn font17 textGreen" [disabled]="isFirstPage()"
                        pTooltip="{{app.translations.GBL_hint_PaginateFirst}}" tooltipPosition="top"
                        [ngClass]="{'cursorPointer': !isFirstPage(), 'cursorNotAllowed': isFirstPage()}">
                        <i class="fa-solid fa-angles-left"></i>
                      </button>
                      <button (click)="prev()" class="btn font17 textGreen"
                        [ngClass]="{'cursorPointer': !isFirstPage(), 'cursorNotAllowed': isFirstPage()}"
                        pTooltip="{{app.translations.GBL_hint_PaginatePrevious}}" tooltipPosition="top"
                        [disabled]="isFirstPage()">
                        <i class="fa-solid fa-chevron-left"></i>
                      </button>
                      <span [ngClass]="darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-white'"
                        class="font15 page-number transition5 b-radius">
                        {{currentpage}}
                      </span>
                      <button (click)="next()" class="btn font17 textGreen"
                        [ngClass]="{'cursorPointer': !isLastPage(), 'cursorNotAllowed': isLastPage()}"
                        pTooltip="{{app.translations.GBL_hint_PaginateNext}}" tooltipPosition="top"
                        [disabled]="isLastPage()">
                        <i class="fa-solid fa-chevron-right"></i>
                      </button>
                      <button (click)="last()" class="btn font17 textGreen" [disabled]="isLastPage()"
                        pTooltip="{{app.translations.GBL_hint_PaginateLast}}" tooltipPosition="top"
                        [ngClass]="{'cursorPointer': !isLastPage(), 'cursorNotAllowed': isLastPage()}">
                        <i class="fa-solid fa-angles-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template [ngIf]="lines.length > 0">
                <ng-container *ngFor="let line of lines">
                  <div [ngStyle]="{'height': !line.expanded ? '115px' : 'auto'}"
                    class="b-radius relative animated fadeIn transition4 font13 tile-container" 
                    [ngClass]="[darkMode && line ? (line.selected ? 'textWhite bg-green' : 'textWhite bg-dark-secondary') : 
                              (!line.selected ? 'textBlack bg-light-blue' : 'textWhite bg-green')]">
                    <div class="row" (click)="compileLines(line)">
                      <ng-container *ngFor="let item of line.list | keyvalue">
                        <ng-container *ngFor="let header of headers">
                          <ng-template [ngIf]="header.visible && item.key.toString() == header.caption">
                            <div class="col-12" style="margin-bottom: 15px;">
                              <span class="bold">{{ item.key }}</span><br>
                              <ng-template
                                [ngIf]="item && (item.key != 'ICON' && item.key != 'IMAGE' 
                                  && !item.value.toString().toLowerCase().includes('true') && !item.value.toString().toLowerCase().includes('false')
                                  && !item.value.toString().includes('</') && !item.value.toString().includes('<br>'))">
                                <span>
                                  <ng-template [ngIf]="item.value.length > 0">{{ item.value }}</ng-template>
                                  <ng-template [ngIf]="item.value.length == 0">-</ng-template>
                                </span>
                              </ng-template>
                              <ng-template [ngIf]="item && (item.key == 'ICON' || item.key == 'IMAGE')">
                                <img [src]="item.value" class="padding2 b-radius" width="30" height="30"
                                  [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-white'"
                                  (dblclick)="expandImage(item.value)">
                              </ng-template>
                              <ng-template
                                [ngIf]="item && (item.value.toString().includes('</') || item.value.toString().includes('<br>'))">
                                <div class="poppins" [innerHTML]="item.value"></div>
                              </ng-template>
                              <ng-template
                                [ngIf]="item && (item.value.toString().toLowerCase().includes('true') || item.value.toString().toLowerCase().includes('false'))">
                                <mat-checkbox [(ngModel)]="item.value" [disabled]="true"
                                  [indeterminate]="checkIndeterminate(item.value)">
                                </mat-checkbox>
                              </ng-template>
                            </div>
                            <br>
                          </ng-template>
                        </ng-container>
                      </ng-container>
                    </div>
                    <i class="fa-solid fa-chevron-down transition4 absolute font13 padding10"
                      (click)="line.expanded = !line.expanded;" style="top: 0px; right: 0px;"
                      [ngClass]="[darkMode && line ? (line.selected ? 'textWhite' : 'textWhite') : (!line.selected ? 'textBlack' : 'textWhite')]"></i>
                  </div>
                  <br>
                </ng-container>
              </ng-template>
              <ng-template [ngIf]="lines.length == 0">
                <span class="font15 padding10 text-centre b-radius inline-block full-width no-records"
                  [ngClass]="darkMode ? 'bg-dark-secondary textWhite' : 'bg-light-blue textBlack'">
                  {{app.translations.GBL_lbl_NoRecords}}
                </span>
              </ng-template>
              
            </div>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>