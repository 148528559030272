import { Component, Input, OnInit } from "@angular/core";
import { RADController } from "app/_controllers/radController";
import { IAction } from "app/_models/IAction";
import { IActionGroup } from "app/_models/IActionGroup";
import { ICompiledLine } from "app/_models/ICompiledLine";
import { IDashboard } from "app/_models/IDashboard";
import { IDashboardGroup } from "app/_models/IDashboardGroup";
import { IGanttTask } from "app/_models/IGanttTask";
import { IHeader } from "app/_models/IHeader";
import { IReport } from "app/_models/IReport";
import { IReportGroup } from "app/_models/IReportGroup";
import { ISourceViewHeader } from "app/_models/ISourceViewHeader";
import { ITab } from "app/_models/ITab";
import { CommunicationService } from "app/_services/communication.service";
import { AppComponent } from "app/app.component";

@Component({
  selector: 'app-rad',
  templateUrl: './rad.component.html',
  styleUrls: ['./rad.component.css']
})
export class RadComponent implements OnInit {
  @Input() darkMode: boolean;
  actionGroups: IActionGroup[] = [];
  reportGroups: IReportGroup[] = [];
  dashboardGroups: IDashboardGroup[] = [];  
  compiledItems: ICompiledLine[] | IGanttTask[] = [];
  tabs: ITab[] = [];
  headers: IHeader[] | ISourceViewHeader[] = [];
  filterButtons: boolean;
  viewName: string;

  constructor(
    public app: AppComponent,
    private radController: RADController,
    private communicationService: CommunicationService
  ) {    
    this.communicationService.grid_or_gantt_rad_loadRad$.subscribe(data => {    
      this.tabs = [];
      this.compiledItems = [];
      this.filterButtons = true;
      this.headers = data.headers;
      this.viewName = data.viewName;
      this.loadActions();
    })

    this.communicationService.gantt_or_detailgrid_rad_updateHeaders$.subscribe(data => {
      this.headers = data;     
    })    

    this.communicationService.grids_or_gantt_rad_filterRadButtons$.subscribe(data => {        
      this.compiledItems = data.compiledItems;
      this.actionGroups = this.radController.filterRadButtons(this.actionGroups, data.currentview, data.compiledItems, true);
      this.reportGroups = this.radController.filterRadButtons(this.reportGroups, data.currentview, data.compiledItems);
      this.dashboardGroups = this.radController.filterRadButtons(this.dashboardGroups, data.currentview, data.compiledItems);      
    })

    this.communicationService.base_rad_collapseRadTabs$.subscribe(() => {
      this.collapseRadTabs();
    })
  }

  ngOnInit(): void { }

  loadActions(): void {
    let nodeId = Number(sessionStorage.getItem('nodeId').toString());
    let userId = Number(sessionStorage.getItem('userId').toString());    
    this.actionGroups = [];
    this.radController.getActions(userId, nodeId).then(actionGroups => {
      if (actionGroups) this.actionGroups = actionGroups;        
      this.loadReports(userId, nodeId);
    });
  }

  loadAction(action: IAction): void {    
    if (action.executePerLine && this.compiledItems.length == 0) {
      this.app.alertError(this.app.translations.ALERT_Error_SelectNoLines);
      return;
    }

    if (action.parameters != null) {
      action.parameters.forEach(param => {
        param.preselectedValue = '';
        param.value = '';
        param.lookupValues = [];
      })
    }

    this.communicationService.showActionDialog(this.headers, action, this.compiledItems);    
  }

  loadReports(userId: number, nodeId: number): void {
    this.reportGroups = [];
    this.radController.getReports(nodeId, userId).then((reportGroups) => {
      if (reportGroups.length > 0) this.reportGroups = reportGroups;
      this.loadDashboards(userId, nodeId);
    });
  }

  loadReport(report: IReport): void {
    this.collapseRadTabs();
    sessionStorage.removeItem('reportLineId');

    if (report.executePerLine) {
      if(this.compiledItems.length != 1) {
        this.app.alertError(this.app.translations.ALERT_Error_OnlySelectOneLine);
        return;
      }

      if (this.compiledItems.length == 1) sessionStorage.setItem('reportLineId', this.compiledItems[0].id);
    }    

    sessionStorage.setItem('reportId', report.id.toString());
    window.open('report-viewer', '_blank');
  }

  loadDashboards(userId: number, nodeId: number): void {
    this.dashboardGroups = [];
    this.radController.getDashboards(nodeId, userId).then((dashboardGroups) => {
      if (dashboardGroups.length > 0) this.dashboardGroups = dashboardGroups;
      this.loadTabs();
    });
  }

  loadDashboard(dashboard: IDashboard): void {
    this.collapseRadTabs();
    sessionStorage.setItem('dashboardId', `${dashboard.id.toString()}|${dashboard.refreshInterval}`);
    window.open('dashboard-viewer', '_blank');
  }

  filterRadButtons(currentViewName: string): void {
    this.actionGroups = this.radController.filterRadButtons(this.actionGroups, currentViewName, this.compiledItems, true);   
    this.reportGroups = this.radController.filterRadButtons(this.reportGroups, currentViewName, this.compiledItems);
    this.dashboardGroups = this.radController.filterRadButtons(this.dashboardGroups, currentViewName, this.compiledItems);    
  }

  loadTabs(): void {
    let index = 0;

    if (this.actionGroups.length > 0) {
      this.tabs.push({ index: index, groupName: this.app.translations.GBL_lbl_ActionsText, data: this.actionGroups, selected: false });
      index++;
    }

    if (this.reportGroups.length > 0) {
      this.tabs.push({ index: index, groupName: this.app.translations.GBL_lbl_ReportText, data: this.reportGroups, selected: false });
      index++;
    }

    if (this.dashboardGroups.length > 0) {
      this.tabs.push({ index: index, groupName: this.app.translations.GBL_lbl_DashboardsText, data: this.dashboardGroups, selected: false });
    }
    
    sessionStorage.setItem('RadLoaded', 'true');
    if (sessionStorage.getItem('filterDirectView')) { 
      //filter rad buttons if grid was loaded with direct link     
      let viewname = sessionStorage.getItem('filterDirectView').split('|')[0];
      this.compiledItems = JSON.parse(sessionStorage.getItem('filterDirectView').split('|')[1]);      
      this.filterRadButtons(viewname);
      sessionStorage.removeItem('filterDirectView');
      return;
    }

    if (this.filterButtons) {
      //filter rad buttons when grid loads
      this.filterRadButtons(this.viewName);
      this.filterButtons = false;
      return;
    }
  }

  changeTab(tab: ITab): void {
    this.tabs.forEach(item => {
      if (tab != item) {
        item.selected = false;
        item.data.forEach(itemGroup => {
          itemGroup.expanded = false;
        })
      }
    })

    tab.selected = !tab.selected;
  }

  expandGroup<T extends (IActionGroup | IReportGroup | IDashboardGroup)>(group: T): void {
    this.tabs.forEach(item => {
      item.data.forEach(itemGroup => {
        itemGroup.expanded = false;
      })
    })

    group.expanded = !group.expanded;
  }

  collapseRadTabs(): void { 
    this.tabs.forEach(item => { item.selected = false; }) 
  }
}