import { Component, ElementRef, HostListener, Input, OnInit, signal, ViewChild } from '@angular/core';
import { CommunicationService } from 'app/_services/communication.service';
import { MapService } from 'app/_services/map.service';
import { AppComponent } from 'app/app.component';
import { ErrorController } from 'app/_controllers/errorController';
import { debounceTime, forkJoin, map, Observable, Subject } from 'rxjs';
import { ILookupValues } from 'app/_models/ILookupValues';
import { IGridConfig } from 'app/_models/IGridConfig';
import { ILine } from 'app/_models/ILine';
import { IFilter } from 'app/_models/IFilter';
import { SortFilterController } from 'app/_controllers/sortFilterController';
import { IHeader } from 'app/_models/IHeader';
import { IActiveFilter } from 'app/_models/IActiveFilter';
import { GridController } from 'app/_controllers/gridController';
import { SelectExpandController } from 'app/_controllers/selectExpandController';
import { ICompiledLine } from 'app/_models/ICompiledLine';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  //#region Fields  
  @Input() darkMode: boolean;
  @Input() globalFontSize: number;
  @Input() tablePadding: number;
  @Input() userId: string;
  @Input() username: string;
  @Input() detailedErrorsEnabled: boolean;
  nodeId: string;
  selectedTabIndex: number = 0;
  mainData: ILookupValues[] = [];
  keyValue: string = undefined;
  extendedData: boolean = false;
  showFilters: boolean = false;
  masterConfig: IGridConfig;
  currentConfig: IGridConfig;
  headers: any[] = [];
  lines: any[] = [];
  map: google.maps.Map;
  mapMarkers: google.maps.Marker[] = [];
  markerData: MarkerData[] = [];
  i = 0;
  filterString: string = '';
  filters: IFilter[] = [];
  sortOrder: boolean = true;
  colSortedBy: string;
  sortString: string;
  dateFormat: string = '-';
  filterGridWithDelay: boolean = true;
  sortChanged: Subject<string> = new Subject<string>();
  filterChanged: Subject<IActiveFilter> = new Subject<IActiveFilter>();
  updatedFilterForGrid: IActiveFilter;
  compiledLines: ICompiledLine[] = [];
  shiftSelect: boolean = false;
  controlSelect: boolean = false;
  //#endregion

  constructor(
    public app: AppComponent,
    private mapService: MapService,
    private communicationService: CommunicationService,
    private errorController: ErrorController,
    public sortFilterController: SortFilterController,
    private gridController: GridController,
    private selectExpandController: SelectExpandController,
  ) {
    this.communicationService.base_map_loadMap$.subscribe(() => {
      this.getData();
    })

    this.filterChanged.pipe(debounceTime(1250)).subscribe(newFilter => {
      if (this.filterGridWithDelay) {
        this.buildFilterString(newFilter.value, newFilter.field, newFilter.isNull);
      }
    });

    this.sortChanged.pipe(debounceTime(1000)).subscribe(() => {
      this.getData();
    });
  }

  ngAfterViewInit(): void {
    this.initMap();
  }

  ngOnInit(): void {
    this.app.hideLoader();
    this.nodeId = sessionStorage.getItem('nodeId');
    this.getMainData();
  }

  initMap() {
    let mapOptions = { zoom: 12, center: { lat: -33.9249, lng: 18.4241 } };
    this.map = new google.maps.Map(document.getElementById('map'), mapOptions);
  }

  getMainData(): void {
    let keys = ['nodeId', 'userId', 'viewName', 'keyfield', 'filterString', 'sortString', 'username'];
    let values = [this.nodeId, this.userId, 'master', '', '', '', this.username];
    let formData = this.app.buildForm(keys, values);
    this.mapService.getData(formData).subscribe({
      next: (data: { resultData: ILookupValues[], masterConfig: IGridConfig }) => {
        this.masterConfig = data.masterConfig;
        this.mainData = data.resultData;
        if (!sessionStorage.getItem('RadLoaded')) {
          this.communicationService.loadRadButtons(this.headers, this.masterConfig.childGrids[this.selectedTabIndex].caption);
        }
      }, error: (errorLog) => {
        if (this.detailedErrorsEnabled) {
          sessionStorage.setItem('errorDetails', JSON.stringify(errorLog.error));
          this.app.router.navigate(["error"]);
          return;
        }

        console.log(errorLog);
        this.app.hideLoader();
        this.app.alertError(`${this.app.translations.ALERT_Error_ErrorWithRefNumberOccured}: ${errorLog.error.refNumber}`); 
      }
    });
  }

  getData(): void {
    this.initMap();
    //#region Setup
    if (this.filters.length == 0) {
      this.filterString = this.sortFilterController.buildFilterStringFromCookies(this.nodeId, true, this.keyValue, this.masterConfig.childGrids[this.selectedTabIndex].caption);
    }

    this.sortString = '';
    let cookies = this.app.cookieService.getAll();
    for (const cookie in cookies) {
      let child = this.masterConfig.childGrids[this.selectedTabIndex];
      if (cookie.includes('sortdetail') && cookie.split('|')[2] == child.name) {
        let cookieNodeId = cookie.split('|')[0].split('sortdetail')[1] == undefined ? 0 : cookie.split('|')[0].split('sortdetail')[1];
        if (cookies.hasOwnProperty(cookie) && cookieNodeId == this.nodeId) {
          let cookieName = cookie.split('|')[1];
          let cookieValue = cookies[cookie];
          this.colSortedBy = cookieName;
          this.sortString = cookieValue;
          this.sortOrder = this.sortString.includes('asc') ? true : false;
          break;
        }
      }
    }
    //#endregion   
    let keys = ['nodeId', 'userId', 'viewName', 'keyfield', 'filterString', 'sortString', 'username'];
    let values = [this.nodeId, this.userId, this.masterConfig.childGrids[this.selectedTabIndex].caption, this.keyValue, this.filterString, this.sortString, this.username];
    let formData = this.app.buildForm(keys, values);
    this.mapService.getData(formData).subscribe({
      next: (data: any) => {
        this.headers = data.headers;
        this.lines = data.lines;

        if (this.filters.length > 0)
          this.headers = this.sortFilterController.reloadHeaderDisplayFormats(this.filters, this.headers);
        if (this.filters.length == 0) this.loadFilters();
        if (this.filterString.trim() != '' && !this.showFilters) this.toggleFilters();

        let result = this.gridController.formatSanitise(data.lines, this.headers, this.filters);
        this.headers = result.headers;
        this.lines = result.lines;
        this.filters = result.filters;

        this.getCurrentGridConfig();
      }, error: (errorLog) => {
        if (this.detailedErrorsEnabled) {
          sessionStorage.setItem('errorDetails', JSON.stringify(errorLog.error));
          this.app.router.navigate(["error"]);
          return;
        }

        console.log(errorLog);
        this.app.hideLoader();
        this.app.alertError(`${this.app.translations.ALERT_Error_ErrorWithRefNumberOccured}: ${errorLog.error.refNumber}`); 
      }
    });
  }

  getCurrentGridConfig(): void {
    this.currentConfig = this.masterConfig.childGrids[this.selectedTabIndex];
    this.markerData = [];
    if (this.currentConfig.viewType == 'Markers') {
      this.lines.forEach(line => {
        let marker: MarkerData = { POSITION: { lat: 0, lng: 0 }, TRKLASTUPDATE: '', NAME: '', SCN__ID: '', label: '' };
        this.headers.forEach(header => {
          let value: {} | string;
          value = (line.list)[header.caption];
          if (header.caption == 'POSITION') {
            value = {
              lat: Number((line.list)[header.caption].split(' ')[0]),
              lng: Number((line.list)[header.caption].split(' ')[1])
            }
          }

          marker[header.caption.toString().split(' ').join('')] = value;
        })

        this.markerData.push(marker);
      });

      this.markerData.forEach(marker => {
        this.createMapMarker(marker.POSITION, marker.NAME, marker.label);
      })

      return;
    }

    if (this.currentConfig.viewType == 'Routes') {
      this.i = 0;
      this.loadTrips();
    }
  }

  loadTrips(): void {
    if (this.i == this.lines.length || this.lines.length == 0) return;

    if (this.i < this.lines.length) {
      let line = this.lines[this.i];
      let keys = ['nodeId', 'userId', 'viewName', 'keyfield', 'filterString', 'sortString', 'username'];
      let values = [this.nodeId, this.userId, this.currentConfig.childGrids[0].caption, line.rowId, '', '', this.username];
      let formData = this.app.buildForm(keys, values);
      this.mapService.getData(formData).subscribe({
        next: (data: any) => {
          let originLat = Number(line.list['LOCATION ORIGIN'].split(', ')[0]);
          let originLng = Number(line.list['LOCATION ORIGIN'].split(', ')[1]);
          let destLat = Number(line.list['LOCATION DESTINATION'].split(', ')[0]);
          let destLng = Number(line.list['LOCATION DESTINATION'].split(', ')[1]);

          if (data.lines.length == 0) {
            this.displayRoute(
              '',
              '',
              {
                lat: originLat,
                lng: originLng,
              },
              {
                lat: destLat,
                lng: destLng
              },
              line.list['NAME']
            );
          } else {
            this.displayRoute(
              '',
              data.lines[0].list['NAME'],
              {
                lat: originLat,
                lng: originLng,
              },
              {
                lat: Number(data.lines[0].list['LOCATION'].split(', ')[0]),
                lng: Number(data.lines[0].list['LOCATION'].split(', ')[1])
              },
              line.list['NAME']
            );

            if (data.lines.length > 1) {
              for (let index = 1; index < data.lines.length; index++) {
                this.displayRoute(
                  data.lines[index - 1].list['NAME'],
                  data.lines[index].list['NAME'],
                  {
                    lat: Number(data.lines[index - 1].list['LOCATION'].split(', ')[0]),
                    lng: Number(data.lines[index - 1].list['LOCATION'].split(', ')[1])
                  },
                  {
                    lat: Number(data.lines[index].list['LOCATION'].split(', ')[0]),
                    lng: Number(data.lines[index].list['LOCATION'].split(', ')[1])
                  },
                  line.list['NAME']
                );
              }
            }

            this.displayRoute(
              data.lines[data.lines.length - 1].list['NAME'],
              '',
              {
                lat: Number(data.lines[data.lines.length - 1].list['LOCATION'].split(', ')[0]),
                lng: Number(data.lines[data.lines.length - 1].list['LOCATION'].split(', ')[1])
              },
              {
                lat: destLat,
                lng: destLng
              },
              line.list['NAME']
            );
          }

          this.i++;
          this.loadTrips();
        }, error: (errorLog) => {
          if (this.detailedErrorsEnabled) {
            sessionStorage.setItem('errorDetails', JSON.stringify(errorLog.error));
            this.app.router.navigate(["error"]);
            return;
          }

          console.log(errorLog);
          this.app.hideLoader();
          this.app.alertError(`${this.app.translations.ALERT_Error_ErrorWithRefNumberOccured}: ${errorLog.error.refNumber}`); 
        }
      });
    } else {
      this.i++;
      this.loadTrips();
    }
  }

  displayRoute(startName: string, endName: string, startCoords: any, endCoords: any, routeName: string) {
    let directionsService = new google.maps.DirectionsService();
    let directionsRenderer = new google.maps.DirectionsRenderer({
      suppressMarkers: true
    });

    directionsRenderer.setMap(this.map);

    let request = {
      origin: startCoords,
      destination: endCoords,
      travelMode: google.maps.TravelMode.DRIVING
    };

    directionsService.route(request, (result: any, status: any) => {
      if (status == 'OK') {
        directionsRenderer.setDirections(result);
        let leg = result.routes[0].legs[0];
        this.createMapMarker(leg.start_location, startName, routeName);
        this.createMapMarker(leg.end_location, endName, routeName);
      }
    });
  }

  createMapMarker(position: any, title: string, label: string) {
    let marker = new google.maps.Marker({
      position: position,
      map: this.map,
      title: title,
      label: title
    });
    this.mapMarkers.push(marker);
  }

  selectValue(event: any): void {
    this.keyValue = event.srcElement.value.toString();
    this.getData();
  }

  changeTab(event: any): void {
    this.filterString = '';
    this.selectedTabIndex = event.index;
    this.filters = [];
    this.showFilters = false;
    this.getData();
  }

  extendData(): void {
    this.extendedData = !this.extendedData;
  }

  expandTile(line: ILine): void {
    line.expanded = !line.expanded;
  }

  //#region |Sort
  sort(header: IHeader): void {
    if (header.displayFormat == 'IMAGE' || header.dataType == 'Byte[]') return;
    let child = this.masterConfig.childGrids[this.selectedTabIndex];
    if (this.colSortedBy != header.field) {
      this.sortOrder = true;
      this.app.cookieService.delete(`sortdetail${this.nodeId}|${this.colSortedBy}|${child.name}`);
    } else {
      this.sortOrder = !this.sortOrder;
    }

    this.colSortedBy = header.field;
    this.sortString = this.sortOrder ? `${header.field} asc` : `${header.field} desc`;
    this.app.cookieService.set(`sortdetail${this.nodeId}|${header.field}|${child.name}`, `${this.sortString}`, 365, '/', '', false);
    this.sortChanged.next(header.field);
  }

  removeSort(header: string): void {
    let child = this.masterConfig.childGrids[this.selectedTabIndex];
    this.app.cookieService.delete(`sortdetail${this.nodeId}|${header}|${child.name}`);
    this.colSortedBy = '';
    this.sortString = '';
    this.sortOrder = true;
    this.getData();
  }
  //#endregion

  //#region |Filter 
  loadFilters(): void { this.filters = this.sortFilterController.loadFilters(this.headers); }

  toggleFilters(): void {
    this.showFilters = !this.showFilters;

    if (!this.showFilters) {
      this.filters = this.sortFilterController.clearAllFilters(this.filters, this.nodeId, this.masterConfig.childGrids[this.selectedTabIndex].caption, this.keyValue);
      this.filterString = '';
      this.getData();
      return;
    }

    let cookies = this.app.cookieService.getAll();
    for (let cookie in cookies) {
      let cookieNodeId = cookie.split('|')[0].split('detailfilter')[1] == undefined ? 0 : cookie.split('|')[0].split('detailfilter')[1];
      if (cookies.hasOwnProperty(cookie) && cookieNodeId == this.nodeId) {
        if (!this.app.cookieService.check('RowFilters') || this.app.cookieService.get('RowFilters') == 'false' && cookie.split('|').length == 2) {
          let result = this.sortFilterController.populateFiltersFromCookies(this.filters, this.headers, cookie, cookies);
          this.filters = result.filters;
          this.headers = result.headers;
        } else if (this.app.cookieService.get('RowFilters') == 'true' && cookie.split('|').length == 4 && this.keyValue.toString() == cookie.split('|')[2]) {
          let result = this.sortFilterController.populateFiltersFromCookies(this.filters, this.headers, cookie, cookies);
          this.filters = result.filters;
          this.headers = result.headers;
        }
      }
    }
  }

  nullifyFilter(filter: IFilter, header: IHeader, event: MouseEvent): void {
    event.preventDefault();
    if (filter.dataType == 'Byte[]') return;
    filter = this.sortFilterController.nullifyFilter(filter, header);
    this.filterChanged.next({ field: filter.field, value: filter.value, isNull: filter.isNull });
  }

  nullifyDateFilter(filter: IFilter, header: IHeader, event: MouseEvent): void {
    event.preventDefault();

    if (filter.isNull) {
      filter.isNull = false;
      filter.value = '';
      return;
    }

    filter = this.sortFilterController.nullifyFilter(filter, header);
  }

  updateValue(value: string, col: string, event: any, filter: IFilter): void {
    filter.isActive = true;
    let val = value == undefined ? event.checked : value;
    if (typeof val == 'string' && val.trim() == '') {
      filter.isNull = false;
      filter.isActive = false;
    }

    if (filter.displayFormat == 'CHECK') filter.isNull = false;
    this.filterGridWithDelay = true;
    this.updatedFilterForGrid = { field: col, value: val, isNull: false };
    this.filterChanged.next({ field: col, value: val, isNull: false });
  }

  createRangeFilter(filter: IFilter): void {
    if (filter.value.includes('null')) {
      this.filterChanged.next({ field: filter.field, value: filter.value, isNull: filter.isNull });
      return;
    }

    if (filter.range[0] == undefined || filter.range[1] == undefined) {
      this.app.alertError(this.app.translations.ALERT_Error_InvalidDateRange);
      return;
    }

    filter = this.sortFilterController.createRangeFilter(filter.range[0], filter.range[1], filter);
    this.buildFilterString(`${filter.displayFormat};${filter.range[0]}|RANGE|${filter.range[1]}`, filter.field, false);

  }

  buildFilterString(value: any, field: string, isNull: boolean): void {
    if (this.updatedFilterForGrid && !this.filterGridWithDelay) {
      this.filterString = this.sortFilterController.buildFilterString(this.updatedFilterForGrid.value, this.updatedFilterForGrid.field, this.filters, Number(this.nodeId), this.updatedFilterForGrid.isNull, true, this.keyValue, this.masterConfig.childGrids[this.selectedTabIndex].caption);
    } else {
      this.filterString = this.sortFilterController.buildFilterString(value, field, this.filters, Number(this.nodeId), isNull, true, this.keyValue, this.masterConfig.childGrids[this.selectedTabIndex].caption);
    }

    this.getData();
  }

  clearFilter(filterToClear: IFilter): void {
    if (filterToClear.dataType == 'Byte[]') return;
    if (!this.app.cookieService.check('RowFilters') || this.app.cookieService.get('RowFilters') == 'false') {
      this.app.cookieService.delete(`detailfilter${this.nodeId}|${filterToClear.field};${this.masterConfig.childGrids[this.selectedTabIndex].caption}`);
    } else {
      let cookies = this.app.cookieService.getAll();
      for (const cookie in cookies) {
        if (cookie.includes(`detailfilter${this.nodeId}|${filterToClear.field};${this.masterConfig.childGrids[this.selectedTabIndex].caption}|${this.keyValue}`)) {
          this.app.cookieService.delete(cookie);
          break;
        }
      }
    }

    this.filterString = this.sortFilterController.clearFilter(filterToClear, this.filters);
    this.getData();
  }

  preSelectCheckBoxFilter(header: IHeader, filterField: string, filterValue: string): boolean {
    return this.sortFilterController.preSelectCheckBoxFilter(header, filterField, filterValue);
  }

  getDateTimeFilterTooltip(filter: IFilter): string {
    return this.sortFilterController.getDateTimeFilterTooltip(filter, this.dateFormat);
  }

  getDateFilterTooltip(filter: IFilter): string {
    return this.sortFilterController.getDateFilterTooltip(filter, this.dateFormat);
  }

  //#endregion  

  checkDateFormat(): string {
    return this.dateFormat.includes('-') ? 'dd-mm-yy' : 'dd/mm/yy';
  }

  openColumnDialog(): void {
    this.communicationService.showColumnDialog(this.headers, this.masterConfig.childGrids[this.selectedTabIndex].name);
  }

  @HostListener('window:keydown', ['$event'])
  keyDownEvent(event: KeyboardEvent): void {
    if (event.key == 'Shift') {
      this.controlSelect = false;
      this.shiftSelect = true;
    }

    if (event.key == 'Control') {
      this.shiftSelect = false;
      this.controlSelect = true;
    }
  }

  @HostListener('window:keyup', ['$event'])
  keyUpEvent(event: KeyboardEvent): void {
    if (event.key == 'Shift') this.shiftSelect = false;
    if (event.key == 'Control') this.controlSelect = false;

    if (event.key == 'Enter' && this.updatedFilterForGrid) {
      this.filterGridWithDelay = false;
      this.buildFilterString(this.updatedFilterForGrid.value, this.updatedFilterForGrid.value.field, this.updatedFilterForGrid.value.isNull);
      this.updatedFilterForGrid = undefined;
    }
  }

  compileLines(line: ILine): void {
    line.selected = !line.selected;
    let viewName = this.masterConfig.childGrids[this.selectedTabIndex].name;
    let result = this.selectExpandController.compileLines(line, this.shiftSelect, this.controlSelect, this.compiledLines, this.lines, '', '', false);
    this.compiledLines = result.compiledLines;
    this.lines = result.lines;
    this.communicationService.filterRadButtons(viewName, this.compiledLines);
  }
}

export interface MarkerData {
  POSITION: {
    lat: number;
    lng: number;
  };
  TRKLASTUPDATE: string;
  NAME: string;
  SCN__ID: string;
  label: string;
}
