import { Component, HostListener, Input, OnInit } from '@angular/core';
import { INotification } from '../_models/INotification';
import { INotificationGroup } from '../_models/INotificationGroup';
import { NotificationsService } from '../_services/notifications.service';
import { AppComponent } from 'app/app.component';
import { CommunicationService } from 'app/_services/communication.service';
import { ErrorController } from 'app/_controllers/errorController';

@Component({
  selector: 'app-notification-component',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {
  selectedTabIndex: number = 0;
  notificationGroups: INotificationGroup[] = [];
  @Input() nodeId: string;
  @Input() userId: string;
  @Input() username: string;
  @Input() darkMode: boolean;

  constructor(
    public app: AppComponent,
    private communicationService: CommunicationService,
    private notificationsService: NotificationsService,
    private errorController: ErrorController
  ) { }

  ngOnInit() { 
    let formData = this.app.buildForm(['userId'], [this.userId]);
    this.notificationsService.getNotifications(formData).subscribe({
      next: (data: INotificationGroup[]) => {
        this.notificationGroups = data;
        let delay = 0.1;

        this.notificationGroups.forEach(notificationGroup => {
          notificationGroup.icon = 'fa-comment';
          if (notificationGroup.category == 'Release Notes') {
            notificationGroup.icon = 'fa-note-sticky';
            notificationGroup.category = this.app.translations.NOT_lbl_ReleaseNotes;
          }

          if (notificationGroup.category == 'Information') {
            notificationGroup.icon = 'fa-envelope';
            notificationGroup.category = this.app.translations.NOT_lbl_Information;
          }

          notificationGroup.notifications.forEach(notification => {
            notification.animationDelay = `${delay}s`;
            delay += 0.05;
          })
        });
      },
      error: (errorLog) => {
        this.errorController.logError(errorLog, 'notificationComponent.ngOnInit() > notificationsService.getNotifications()');
      }
    });  
  }  

  readNotification(notification: INotification): void {
    notification.read = true;
    let formData = this.app.buildForm(['userId', 'notificationId'], [this.userId, notification.id]);
    this.notificationsService.readNotification(formData).subscribe({
      error: (errorLog) => {
        this.errorController.logError(errorLog, 'notificationComponent.readNotification() > notificationsService.readNotification()');
      }
    });
  }

  deleteNotification(notification: INotification): void {
    this.notificationGroups.forEach(notificationGroup => {
      let index = notificationGroup.notifications.indexOf(notification);
      if (index !== -1) notificationGroup.notifications.splice(index, 1);
    })

    let formData = this.app.buildForm(['userId', 'notificationId'], [this.userId, notification.id]);
    this.notificationsService.deleteNotification(formData).subscribe({
      error: (errorLog) => {
        this.errorController.logError(errorLog, 'notificationComponent.deleteNotification() > notificationsService.deleteNotification()');
      }
    });
  }

  changeTab(event: any): void { this.selectedTabIndex = event.index; }
  close(): void { this.communicationService.closeDialog(); }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Esc') this.close();
  }
}