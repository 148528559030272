<p-dialog [visible]="true" [breakpoints]="{'430px': '100%'}" [style]="{width: '50%'}" [baseZIndex]="10000" [modal]="true" [maximizable]="false"
  [draggable]="false" [resizable]="false"
  [ngClass]="{'change-scrollbar-color-darkMode': darkMode, 'change-scrollbar-color': !darkMode}">
  <div class="row sticky dialog-title-container" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-12 unset-p">
      <div class="flex relative align-centre dialog-title">
        <span class="textGreen font16 b-radius dialog-title-icon"
          [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
          <i class="fa-solid fa-pen"></i>
        </span>
        <span class="font17" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{action.caption}}</span>
        <div class="absolute" style="right: 15px;">
          <span class="textGreen font16 b-radius dialog-title-icon cursorPointer" (click)="close()"
            [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <!--#region Modal Body -->
  <div class="row padding15" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <ng-container *ngFor="let param of parameters">
      <div class="offset-1 col-9 padding15">
        <div class="flex animated fadeIn align-end" style="height: 35px;">
          <span class="font14" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{param.caption}}</span>
        </div>
        <div class="animated fadeIn">
          <ng-template [ngIf]="param.type == 'combolookup'">
            <select #ddl class="font14 cursorPointer b-radius full-width form-select"
              [ngClass]="[darkMode ? 'textWhite' : 'textBlack']"
              (change)="updateCombos(param, $event)">
              <option class="cursorPointer textBlack" [value]="0">{{app.translations.GBL_lbl_SelectOption}}</option>
              <ng-container *ngFor="let val of param.lookupValues">
                <ng-template [ngIf]="val.id == '-1'">
                  <option class="cursorPointer textBlack" [value]="val.value"
                    [selected]="val.value == param.preselectedValue">
                    {{val.value}}
                  </option>
                </ng-template>
                <ng-template [ngIf]="val.id > 0">
                  <option class="cursorPointer textBlack" [value]="val.id"
                    [selected]="val.value == param.preselectedValue">
                    {{val.value}}
                  </option>
                </ng-template>
              </ng-container>
            </select>
          </ng-template>
          <ng-template [ngIf]="param.type == 'combo' || param.type == 'combobox'">
            <select #ddl class="font14 cursorPointer full-width form-select"
              [ngClass]="[darkMode ? 'textWhite' : 'textBlack']"
              (change)="updateCombos(param, $event)">
              <option class="cursorPointer textBlack" [value]="0">{{app.translations.GBL_lbl_SelectOption}}</option>
              <ng-container *ngFor="let val of param.lookupValues">
                <option class="cursorPointer textBlack" [value]="val" [selected]="val == param.preselectedValue">
                  {{val}}
                </option>
              </ng-container>
            </select>
          </ng-template>
          <ng-template [ngIf]="(param.type == 'text' || param.type == 'textbox') && (param.dataType == 'Int32' || param.dataType == 'Int16')">
            <input type="number" class="form-control font13" step="1" pattern="[0-9]*"
              (change)="updateNumericInputs(param, $event)"
              [value]="compiledItems.length == 1 ? param.preselectedValue : ''"
              [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" />
          </ng-template>
          <ng-template [ngIf]="(param.type == 'text' || param.type == 'textbox') && (param.dataType == 'Decimal' || param.dataType == 'Double')">
            <input type="number" class="form-control font13"
              (change)="updateNumericInputs(param, $event)"
              [value]="compiledItems.length == 1 ? param.preselectedValue.replace(',', '.') : ''"
              [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" />
          </ng-template>
          <ng-template
            [ngIf]="(param.type == 'date' || param.type == 'datebox') && (param.dataType == '' || param.dataType == 'DateTime')">
            <input type="date" class="form-control font13"
              (change)="updateTextInputs(param, $event)"
              [value]="compiledItems.length == 1 ? param.preselectedValue : ''"
              [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" />            
          </ng-template>
          <ng-template
            [ngIf]="(param.type == 'datetime' || param.type == 'datetimebox') && (param.dataType == '' || param.dataType == 'DateTime')">
            <input type="datetime-local" class="form-control font13"
              (change)="updateTextInputs(param, $event)"
              [value]="compiledItems.length == 1 ? param.preselectedValue : ''"
              [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" />            
          </ng-template>
          <ng-template
            [ngIf]="(param.type == 'time' || param.type == 'timebox') && (param.dataType == '' || param.dataType == 'DateTime' || param.dataType == 'TimeSpan')">
            <input type="time" class="form-control font13"
              (change)="updateTextInputs(param, $event)"
              [value]="compiledItems.length == 1 ? param.preselectedValue : ''"
              [ngClass]="[darkMode ? 'textWhite' : 'textBlack']" />
          </ng-template>
          <ng-template
            [ngIf]="(param.type == 'text' || param.type == 'textbox') && (param.dataType == '' || param.dataType == 'String' || param.dataType == undefined)">
            <input type="text" class="form-control font13"
              (change)="updateTextInputs(param, $event)"
              [value]="compiledItems.length == 1 ? param.preselectedValue : ''"
              [ngClass]="darkMode ? 'textWhite' : 'textBlack'" />
          </ng-template>
          <ng-template [ngIf]="param.type == 'textarea'">
            <div class="relative">
              <textarea class="form-control font13 pre-wrap-white-space transition5" (blur)="loadLookupsFromTextArea(param)"
                [(ngModel)]="param.preselectedValue"
                (keyup)="updateTextInputs(param, $event)" rows="5"
                style="padding: 5px 25px 5px 5px !important;"
                [ngStyle]="{'border-color': sqlError.length > 0 ? 'var(--red)' : '#ced4da'}"
                [ngClass]="[darkMode ? 'textWhite' : 'textBlack']"></textarea>
              <ng-template [ngIf]="sqlError.length > 0">
                <i class="fa-solid fa-circle-info absolute font14 textRed cursorPointer" style="top: 7px; right: 10px;"
                  pTooltip="{{sqlError}}" tooltipPosition="top"></i>
              </ng-template>
            </div>
          </ng-template>
          <ng-template [ngIf]="param.type == 'password'">
            <input type="password" autocomplete="new-password"
              (change)="updateTextInputs(param, $event)"
              class="form-control font13" [value]="compiledItems.length == 1 ? param.preselectedValue : ''"
              [ngClass]="darkMode ? 'textWhite' : 'textBlack'" />
          </ng-template>
          <ng-template [ngIf]="param.type == 'check' || param.type == 'toggle' || param.type == 'checkbox'">
            <mat-checkbox [checked]="param.preselectedValue"
              (change)="updateCheckboxToggles(param, $event)">
            </mat-checkbox>
          </ng-template>
          <ng-template [ngIf]="param.type == 'image' || param.type == 'imagebox'">
            <label class="" (click)="fileInput.value = ''" pTooltip="{{app.translations.GBL_hint_UploadImage}}"
              tooltipPosition="top">
              <i class="fa-solid fa-arrow-up-from-bracket font20 cursorPointer b-radius padding10"
              [ngClass]="darkMode ? 'bg-dark-secondary textWhite' : 'bg-light-blue textGreen'"></i>
              <input #fileInput type="file" class="display-none" (change)="updateImage(param, $event)">
            </label>
            <ng-template [ngIf]="param.preselectedValue || compiledItems.length > 1">
              <button class="font19 cursorPointer b-radius padding10 transition4 unset-b float-right"
                (click)="removeImage(param)" [ngClass]="darkMode ? 'bg-dark-secondary textWhite' : 'bg-light-blue textGreen'">
                <i class="fa-solid fa-trash"></i>
              </button>
            </ng-template>
            <img [src]="param.preselectedValue" class="full-width b-radius" style="margin-top: 30px;" />
          </ng-template>
        </div>
      </div>
      <br /><br />
    </ng-container>
  </div>
  <!--#endregion -->
  <div class="row sticky dialog-footer-container" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-12 unset-p">
      <div class="flex justify-right padding10 dialog-footer">
        <button class="btn font17" (click)="confirm()">
          <i class="fa-solid fa-floppy-disk textGreen"></i>
          <span [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
            {{app.translations.GBL_btn_SaveText}}
          </span>
        </button>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog [visible]="showConfirmation" [breakpoints]="{'430px': '100%'}" [style]="{width: '50%'}" [baseZIndex]="10000" [modal]="true"
  [maximizable]="false" [draggable]="false" [resizable]="false">
  <div class="">
    <div class="row sticky dialog-title-container" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
      <div class="col-12 unset-p">
        <div class="flex relative align-centre dialog-title">
          <span class="textGreen font16 b-radius dialog-title-icon"
            [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
            <i class="fa-solid fa-pen"></i>
          </span>
          <span class="font15"
            [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.CMD_lbl_Title}}</span>
          <div class="absolute" style="right: 15px;">
            <span class="textGreen font16 b-radius dialog-title-icon cursorPointer" (click)="showConfirmation = false"
              [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
              <i class="fa-solid fa-xmark"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="padding10" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
      <div class="row">
        <div class="col-6 unset-p">
          <span class="font14 padding5 b-radius"
            [ngClass]="darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-light-blue'">{{app.translations.CMD_lbl_Field}}</span>
        </div>
        <div class="col-6 unset-p">
          <span class="font14 padding5 b-radius"
            [ngClass]="darkMode ? 'textWhite bg-dark-secondary' : 'textBlack bg-light-blue'">{{app.translations.CMD_lbl_NewValue}}</span>
        </div>
      </div>      
    </div>
    <div class="padding10" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
      <div class="row">
        <ng-container *ngFor="let param of parameters">
          <ng-template [ngIf]="param.value">
            <div class="col-6 unset-p">
              <span class="font14" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{param.caption}}</span>
            </div>
            <div class="col-6 unset-p">
              <ng-template [ngIf]="(param.type == 'image' || param.type == 'imagebox') && param.value != 'removed'">
                <img [src]="'data:image/png;base64,' + param.value" style="width: 15%;" />
              </ng-template>
              <ng-template [ngIf]="(param.type == 'image' || param.type == 'imagebox') && param.value == 'removed'">
                <span class="font14" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_lbl_Removed}}</span>
              </ng-template>
              <ng-template [ngIf]="param.type != 'image' && param.type != 'imagebox'">
                <span class="font14" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{param.value}}</span>
              </ng-template>
            </div>
          </ng-template>
        </ng-container>
      </div>
    </div>
    <div class="padding10" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
      <div class="row">
        <div class="flex relative bg-orange b-radius padding15 align-centre" style="height: 70px;">
          <span class="font30" [ngClass]="darkMode ? 'textBlack' : 'textWhite'">
            <i class="fa-solid fa-triangle-exclamation" style="margin-right: 20px;"></i>
          </span>
          <span class="font16 align-centre" [ngClass]="darkMode ? 'textBlack' : 'textWhite'">
            {{compiledItems.length}} {{app.translations.CMD_lbl_UpdateWarning}}
          </span>
        </div>
      </div>
    </div>    
  </div>
  <div class="row sticky dialog-footer-container" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-12 unset-p">
      <div class="flex justify-right padding10 dialog-footer">
        <button class="btn font17" (click)="save()">
          <i class="fa-solid fa-check-double textGreen"></i>
          <span [ngClass]="darkMode ? 'textWhite' : 'textBlack'">{{app.translations.GBL_btn_ConfirmText}}</span>
        </button>
      </div>
    </div>
  </div>
</p-dialog>