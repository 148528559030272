import { Component, Input, HostListener, OnInit } from '@angular/core';
import { IHeader } from '../_models/IHeader';
import { AppComponent } from 'app/app.component';
import { CommunicationService } from 'app/_services/communication.service';
import { GridController } from 'app/_controllers/gridController';

@Component({
  selector: 'column-component',
  templateUrl: './column.component.html',
  styleUrls: ['./column.component.css']
})
export class ColumnComponent implements OnInit {
  //#region Fields
  @Input() headers: IHeader[] = [];
  @Input() nodeId: string;
  @Input() userId: string;
  @Input() username: string;
  @Input() darkMode: boolean;
  @Input() viewName: string;
  updated: boolean = false;
  selectAll: boolean = false;
  //#endregion

  constructor(
    public app: AppComponent,
    private gridController: GridController,
    private communicationService: CommunicationService
  ) { }

  ngOnInit() { }

  update(headerToUpdate: string, event: any): void {
    this.updated = true;
    let index = this.headers.findIndex(header => header.caption === headerToUpdate);
    this.headers[index].visible = event.checked;
  }

  updateAll(): void {
    this.updated = true;
    this.selectAll = !this.selectAll;
    this.headers.forEach(header => { header.visible = this.selectAll; })
  }

  validate(): boolean {
    let visibleCount = this.headers.filter(header => header.visible).length;
    if (visibleCount == 0) return false;
    return true;
  }

  removeCookies(): void {
    let cookies = this.app.cookieService.getAll();
    this.headers.forEach(header => {
      if (!header.visible) {
        for (const cookie in cookies) {
          if (cookies.hasOwnProperty(cookie) && cookie.includes(header.field)) {
            this.app.cookieService.delete(cookie);
          }
        }
      }
    })
  }

  save(): void {
    if (!this.updated) {
      this.app.alertInfo(this.app.translations.ALERT_Info_NothingToSave);
      return;
    }

    if (!this.validate()) {
      this.app.alertError(this.app.translations.ALERT_Error_AllHeadersHidden); 
      return;
    }

    this.removeCookies();
    this.app.showLoader();               
    this.gridController.saveLayout(this.nodeId, this.viewName, this.username, this.headers).then(result => {
      if (result) {
        this.app.hideLoader();
        this.communicationService.closeColumnDialog();
        this.communicationService.refreshAll();
      }
    });
  }

  close(): void {
    if (this.updated) {
      this.app.alertError(this.app.translations.ALERT_Error_CloseWithoutSave);
      return;
    }
    this.communicationService.closeColumnDialog();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.key === 'Escape' || event.key === 'Esc') this.close();
  }
}