import { Component, Injectable, OnInit } from '@angular/core';
import { DatePipe, ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ITranslations } from './_models/ITranslations';
import { ErrorController } from './_controllers/errorController';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { UtilService } from './_services/util.service';
import { CommunicationService } from './_services/communication.service';
import { ITranslationsClass } from './_models/ITranslationsClass';
import { ClipboardService } from 'ngx-clipboard';
import DxThemes from 'devextreme/ui/themes';

@Injectable({ providedIn: 'root' })
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  //#region Fields
  dismiss: string;
  type: string;
  message: string;
  icon: string;
  showLoaderModal: boolean = false;
  nodeId: string;
  userId: string;
  username: string;
  darkMode: boolean;
  translations: ITranslations;
  siteName: string;
  userRole: string;
  translationsClass: ITranslationsClass = new ITranslationsClass;
  //#endregion

  constructor(
    public router: Router,
    public datePipe: DatePipe,
    public domSanitizer: DomSanitizer,
    public cookieService: CookieService,
    public clipboard: ClipboardService,
    private scroll: ViewportScroller,
    private errorController: ErrorController,
    private utilService: UtilService,
    private communicationService: CommunicationService
  ) { 
    if (this.cookieService.check('darkMode'))
      this.darkMode = this.tryParseBoolean(this.cookieService.get('darkMode'));
      
    //set gantt and dashboard theme
    DxThemes.current(this.darkMode ? 'generic.dark' : 'generic.light');  
  }

  ngOnInit() {
    this.alertInit();
    this.getSiteName();
  }

  getSiteName() {
    this.utilService.getSiteName().subscribe({
      next: (data: { result: string }) => {
        this.siteName = data.result;
        this.getTranslations();
      }, error: (errorLog) => {
        this.errorController.logError(errorLog, 'appComponent.getSiteName() > utilService.getSiteName()');
      }
    })
  }

  getTranslations(): void {
    let userId = this.cookieService.check('loggedIn') ? Number(this.cookieService.get('loggedIn').split('|')[1]) : -1;
    let formData = this.buildForm(['userId'], [userId.toString()]);
    this.utilService.getTranslations(formData).subscribe({
      next: (data) => {
        Object.keys(this.translationsClass).forEach(transkey => {
          if (data.hasOwnProperty(transkey) && this.translationsClass[transkey] !== data[transkey]) {
            this.translationsClass[transkey] = data[transkey];
          }
        });

        this.translations = this.translationsClass;
        this.communicationService.translationLoaded();
      }, error: (errorLog) => {
        this.errorController.logError(errorLog, 'appComponent.getTranslations() > utilService().getTranslations()');
      }
    })
  }

  alertSuccess(message: string): void {
    this.type = 'success';
    this.message = message;
    this.icon = 'circle-check';
    setTimeout(() => this.dismiss = 'slideOutRight', 2000);
    this.dismiss = '';
  }

  alertError(message: string): void {
    this.type = 'danger';
    this.message = message;
    this.icon = 'circle-xmark';
    setTimeout(() => this.dismiss = 'slideOutRight', 2000);
    this.dismiss = '';
  }

  alertInfo(message: string): void {
    this.type = 'info';
    this.message = message;
    this.icon = 'circle-info';
    setTimeout(() => this.dismiss = 'slideOutRight', 2000);
    this.dismiss = '';
  }

  alertInit(): void { 
    setTimeout(() => this.dismiss = 'slideOutRight', 500); 
  }

  showLoader(): void {
    this.scroll.scrollToPosition([0, 0]);
    this.showLoaderModal = true;
  }

  hideLoader(): void { 
    this.showLoaderModal = false; 
  }
    
  buildForm(keys: string[], values: string[]): FormData {
    let formData = new FormData();
    keys.forEach((key, i) => {
      formData.append(key, encodeURIComponent(values[i] == null ? null : values[i].toString()));
    })
    return formData;
  }

  tryParseBoolean(value: any): any {
    if (typeof value === 'boolean') return value;

    if (typeof value === 'string') {
      let lowercaseValue = value.trim().toLowerCase();
      if (lowercaseValue === 'true' || lowercaseValue === '1') return true;
      if (lowercaseValue === 'false' || lowercaseValue === '0') return false;
    }

    return value;
  }
}
