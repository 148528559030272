import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, throwError } from 'rxjs';
import { header } from 'app/_misc/headers';
import { IActionGroup } from 'app/_models/IActionGroup';
import { IReportGroup } from 'app/_models/IReportGroup';
import { IDashboardGroup } from 'app/_models/IDashboardGroup';
import { ILookupValues } from 'app/_models/ILookupValues';
import { ISourceViewHeader } from 'app/_models/ISourceViewHeader';

@Injectable({ providedIn: 'root' })
export class RADService {
  constructor(private httpClient: HttpClient) { }

  getActions(formData: FormData) {
    return this.httpClient.post('/rad/getactions', formData, {
      headers: new header().FormHeader, observe: 'response'
    }).pipe(map(response => {
      return response.body as IActionGroup[];
    }), catchError(this.handleError));
  }

  getReports(formData: FormData) {
    return this.httpClient.post('rad/getreports', formData, {
      headers: new header().FormHeader, observe: 'response'
    }).pipe(map(response => {
      return response.body as IReportGroup[];
    }), catchError(this.handleError));
  }

  getDashboards(formData: FormData) {
    return this.httpClient.post('/rad/getdashboards', formData, {
      headers: new header().FormHeader, observe: 'response'
    }).pipe(map(response => {
      return response.body as IDashboardGroup[];
    }), catchError(this.handleError));
  }

  getLookupData(formData: FormData) {
    return this.httpClient.post('rad/getlookupdata', formData, {
      headers: new header().FormHeader, observe: 'response'
    }).pipe(map(response => {      
      return response.body as ILookupValues[];
    }), catchError(this.handleError));
  }

  getSourceViewHeaders(formData: FormData) {
    return this.httpClient.post('rad/getsourceviewheaders', formData, {
      headers: new header().FormHeader, observe: 'response'
    }).pipe(map(response => {      
      return response.body as ISourceViewHeader[];
    }), catchError(this.handleError));
  }

  sqlExecute(formData: FormData) {
    return this.httpClient.post('/rad/sqlexecute', formData, {
      headers: new header().FormHeader, observe: 'response'
    }).pipe(map(response => {
      return response.body;
    }), catchError(this.handleError));
  }

  createUpdateSingleRecord(formData: FormData) {
    return this.httpClient.post('/rad/createupdatesinglerecord', formData, {
      headers: new header().FormHeader, observe: 'response'
    }).pipe(map(response => {
      return response.body;
    }), catchError(this.handleError));
  }

  updateMultipleRecords(formData: FormData) {
    return this.httpClient.post('/rad/updatemultiplerecords', formData, {
      headers: new header().FormHeader, observe: 'response'
    }).pipe(map(response => {
      return response.body;
    }), catchError(this.handleError));
  }

  updateMultipleRecordsPrepopulated(formData: FormData) {
    return this.httpClient.post('/rad/updatemultiplerecordsprepopulated', formData, {
      headers: new header().FormHeader, observe: 'response'
    }).pipe(map(response => {
      return response.body;
    }), catchError(this.handleError));
  }
    
  handleError(error: any) {
    return throwError(error);
  }
}