<p-dialog [visible]="true" [breakpoints]="{'430px': '100%'}" [style]="{width: '40%'}" [baseZIndex]="10000" [modal]="true" [maximizable]="false"
  [draggable]="false" [resizable]="false">
  <div class="row sticky dialog-title-container" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-12 unset-p">
      <div class="flex relative align-centre dialog-title">
        <span class="textGreen font16 b-radius dialog-title-icon"
          [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
          <i class="fa-solid fa-square-check"></i>
        </span>
        <ng-template [ngIf]="action != undefined">
          <span class="font17" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
            {{action.caption}}
          </span>
        </ng-template>
        <ng-template [ngIf]="action == undefined">
          <span class="font17" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
            {{app.translations.GBL_lbl_ActionResult}}
          </span>
        </ng-template>
        <div class="absolute" style="right: 15px;">
          <span class="textGreen font16 b-radius dialog-title-icon cursorPointer" (click)="close()"
            [ngClass]="darkMode ? 'bg-dark-secondary' : 'bg-light-blue'">
            <i class="fa-solid fa-xmark"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-12 animated fadeIn padding15">
      <ng-template [ngIf]="action != undefined">
        <ng-template [ngIf]="action.confirmationPrompt">
          <div class="font14 poppins" [innerHtml]="action.confirmationPrompt"
            [ngClass]="darkMode ? 'textWhite' : 'textBlack'"></div>
        </ng-template>
        <ng-template [ngIf]="!action.confirmationPrompt">
          <span class="font14" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
            {{app.translations.CON_lbl_DefaultPrompt1}} {{action.caption}} {{app.translations.CON_lbl_DefaultPrompt2}}?
          </span>
        </ng-template>
      </ng-template>
      <ng-template [ngIf]="action == undefined">
        <span class="font14" [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
          <div class="font14 poppins" [innerHtml]="result"
            [ngClass]="darkMode ? 'textWhite' : 'textBlack'"></div>
        </span>
      </ng-template>
    </div>
  </div>
  <div class="row sticky dialog-footer-container" [ngClass]="darkMode ? 'bg-dark' : 'bg-white'">
    <div class="col-12 unset-p">
      <div class="flex justify-right padding10 dialog-footer">
        <ng-template [ngIf]="action">
          <button class="btn font17" (click)="confirmDialog()">
            <i class="fa-solid fa-check-double textGreen"></i>
            <span [ngClass]="darkMode ? 'textWhite' : 'textBlack'">
              {{app.translations.GBL_btn_ConfirmText}}
            </span>
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</p-dialog>
