import { Component, Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable({ providedIn: 'root' })
@Component({ template: '' })
export class Direct {
  queryParams: { key: string, value: string }[] = [];

  constructor(
    private router: Router,    
    private cookieService: CookieService
  ) {
    const urlTree: UrlTree = this.router.parseUrl(this.router.url);
    const queryParams = urlTree.queryParams;

    Object.keys(queryParams).forEach(key => {
      this.queryParams.push({
        key: key,
        value: queryParams[key]
      });
    });   
    
    if(!this.cookieService.check('loggedIn')) window.location.href = '/signin';

    if (this.queryParams[0].key == 'view') {
      sessionStorage.setItem('directURL', window.location.search);
      sessionStorage.setItem('directedNodeId', this.queryParams[0].value);
      if (this.queryParams[1]) {
        sessionStorage.setItem('directLineId', this.queryParams[1].value);
      }
            
      this.router.navigate(['base']);
    } else if (this.queryParams[0].key == 'report') {
      sessionStorage.removeItem('directReportLineId');
      sessionStorage.setItem('directReportURL', window.location.search);
      sessionStorage.setItem('directedReportId', this.queryParams[0].value);
      if (this.queryParams[1]) {
        sessionStorage.setItem('directReportLineId', this.queryParams[1].value);
      }

      this.router.navigate(['report-viewer']);
    } else if (this.queryParams[0].key == 'dashboard') {
      sessionStorage.removeItem('directDashboardLineId');
      sessionStorage.setItem('directDashboardURL', window.location.search);
      sessionStorage.setItem('directedDashboardId', this.queryParams[0].value);
      if (this.queryParams[1]) {
        sessionStorage.setItem('directDashboardLineId', this.queryParams[1].value);
      }

      this.router.navigate(['dashboard-viewer']);
    }
  }
}